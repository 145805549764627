import BaseInput from '@/components/Inputs/BaseInput.vue';
import FileInput from '@/components/Inputs/FileInput.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import Modal from '@/components/Modal.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import Badge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseAlert from "@/components/BaseAlert";
import BaseNav from "@/components/Navbar/BaseNav";
import BaseHeader from '@/components/BaseHeader';
import Tab from '@/components/Tabs/Tab.vue';
import Tabs from '@/components/Tabs/Tabs.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Input, Tooltip, Popover, Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, Select, Option, Autocomplete, Collapse, CollapseItem, Drawer, Image, Dialog, Checkbox, Button, Tree, DatePicker} from 'element-ui';
import CKEditor from 'ckeditor4-vue';
import Unauthorize from '@/components/Unauthorize';
import Expired from '@/components/Expired';
import Tracking from '@/components/Tracking';
import McrItemNote from '@/components/McrItemNote';
import PageLoading from '@/components/PageLoading';
import SkeletonLoading from '@/components/SkeletonLoading';
import CustomPagination from '@/components/CustomPagination';
import SmartCatAttachment from '@/components/SmartCatAttachment';
import CheckDuplicateWorkbench from '@/components/CheckDuplicate/CheckDuplicateWorkbench.vue';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import Back from '@/components/Back';
import MescCharacteristic from "@/components/Mesc/MescCharacteristic";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install(Vue) {
        Vue.component(Badge.name, Badge);
        Vue.component(BaseAlert.name, BaseAlert);
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(BaseCheckbox.name, BaseCheckbox);
        Vue.component(BaseHeader.name, BaseHeader);
        Vue.component(BaseInput.name, BaseInput);
        Vue.component(FileInput.name, FileInput);
        Vue.component(BaseDropdown.name, BaseDropdown);
        Vue.component(BaseNav.name, BaseNav);
        Vue.component(BasePagination.name, BasePagination);
        Vue.component(BaseProgress.name, BaseProgress);
        Vue.component(BaseRadio.name, BaseRadio);
        Vue.component(BaseSwitch.name, BaseSwitch);
        Vue.component(Card.name, Card);
        Vue.component(Modal.name, Modal);
        Vue.component(StatsCard.name, StatsCard);
        Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
        Vue.component(Input.name, Input);
        Vue.component(Table.name, Table);
        Vue.component(TableColumn.name, TableColumn);
        Vue.component(Dropdown.name, Dropdown);
        Vue.component(DropdownItem.name, DropdownItem);
        Vue.component(DropdownMenu.name, DropdownMenu);
        Vue.component(Select.name, Select);
        Vue.component(Option.name, Option);
        Vue.component(Autocomplete.name, Autocomplete);
        Vue.component(Tab.name, Tab);
        Vue.component(Tabs.name, Tabs);
        // Vue.component(TabPane.name, TabPane);
        Vue.component(Collapse.name, Collapse);
        Vue.component(CollapseItem.name, CollapseItem);
        Vue.component(Drawer.name, Drawer);
        Vue.component(Image.name, Image);
        Vue.component(Dialog.name, Dialog);
        Vue.component(Checkbox.name, Checkbox)
        Vue.component(Button.name, Button)
        Vue.component(Tree.name, Tree)
        Vue.component(DatePicker.name, DatePicker)
        Vue.component('validation-provider', ValidationProvider)
        Vue.component('validation-observer', ValidationObserver)
        Vue.component(Unauthorize.name, Unauthorize);
        Vue.component(Expired.name, Expired);
        Vue.component(Tracking.name, Tracking);
        Vue.component(McrItemNote.name, McrItemNote);
        Vue.component(PageLoading.name, PageLoading);
        Vue.component(SkeletonLoading.name, SkeletonLoading);
        Vue.component(CustomPagination.name, CustomPagination);
        Vue.component(SmartCatAttachment.name, SmartCatAttachment);
        Vue.component(CheckDuplicateWorkbench.name, CheckDuplicateWorkbench)
        Vue.use(Tooltip);
        Vue.use(Popover);
        Vue.use(CKEditor);
        Vue.component(BarChart.name, BarChart)
        Vue.component(LineChart.name, LineChart)
        Vue.component(Back.name, Back);
        Vue.component(MescCharacteristic.name, MescCharacteristic);

    }
};

export default GlobalComponents;