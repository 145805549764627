import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('MaterialType?page=' + page, {params});
    }, 
    getByMtypeCode(code) {
        return Service().get('MaterialType/getByMtypeCode/' + code);
    }, 
    show(id) {
        return Service().get('MaterialType/show/'+id);
    },    
    create(params) {
        return Service().post('MaterialType/create', params);
    },
    update(id, params) {
        return Service().post('MaterialType/update/'+id, params);
    },
    delete(id) {
        return Service().post('MaterialType/delete/'+id);
    },
    material_group(code) {
        return Service().get('MaterialType/material_group/'+code);
    },
    add_relation(params) {
        return Service().post('MaterialType/add-relation/3', params);
    },
    delete_relation(id) {
        return Service().post('MaterialType/delete-relation/'+id);
    },
}