import Service from '@/helpers/service'

export default {

	real_time_notification() {
        return Service().get('real-time-notification');
    },
    get(page, params) {
        return Service().get('all-notifications?page=' + page, {params});
    },
    delete(id) {
        return Service().post('notifications/delete/'+id);
    },
    mark_as_read(id) {
        return Service().post('mark-as-read/'+id);
    },
}