import Dialog from '@/helpers/dialog';
import router from '@/routes/router'

export default (context, service) => {
    let api = {
        default: {
            onSuccess(response) {
                
            },
            onError(error) {                             
                if (error.response.data.message) {
                    context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });            
                } else {
                    context.$notify({
                        message: 'Internal Server Error',                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });  
                }
            },
            onFinish() {
                
            },
        },
        onSuccess(handler) {            
            api.successHandling = handler 
            return api; 
        },
        onError(handler) {            
            api.errorHandling = handler  
            return api; 
        },
        onFinish(handler) {
            api.finishHandling = handler
            return api; 
        },
        successHandling: function(response) {
            api.default.onSuccess(response)
        },
        errorHandling: function(error) {
            api.default.onError(error)
        },
        finishHandling: function() {
            api.default.onFinish()
        },
        call: function() {
            service.then(response => {                
                api.successHandling(response)    
            }).catch(error => {
                if (error.response.status == 401) {
                    localStorage.removeItem('auth');
                    router.push('/login');
                }   
                api.errorHandling(error)    
            }).then(() => {
                api.finishHandling()
            })
        }
    }    
    return api
}