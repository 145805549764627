<template>
  <div class="main-page">
    <router-view></router-view>
    <notifications></notifications>
  </div>
</template>

<script>
export default {
  watch: {
    '$route' (to, from) {
      if (to.meta.title) {
        document.title = to.meta.title+' - SMARTCAT';
      } else {
        document.title = 'SMARTCAT';
      }
    }
  }
}
</script>
