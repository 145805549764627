<template>
    <vue-content-loading :width="200" :height="100">
      <rect x="7" y="13" rx="4" ry="4" width="100" height="7" />
      <rect x="7" y="25" rx="4" ry="4" width="150" height="7" />
      <rect x="7" y="50" rx="4" ry="4" width="185" height="30" />
    </vue-content-loading>
</template>

<script>
  import VueContentLoading from 'vue-content-loading';
  
  export default {
      components: {
            VueContentLoading
      },
      name: 'skeleton-loading'
  };
</script>