import router from '@/routes/router'
import authHeader from '@/helpers/authHeader';
import Api from '@/helpers/api';
import authService from '@/services/auth.service'

const auth = JSON.parse(localStorage.getItem('auth'));

const state =  auth ? auth : {
    authenticated: false,
    expired: false,
    user: {},
    abilities: {},
    userConfig:{}
}

const actions = {
    setToken({dispatch, commit}, token) {        
        authHeader.set(token);
    },
    attemp({dispatch, commit}, data) {        
        commit('set', data);        
    },
    expired({dispatch, commit}) {
        commit('expired');
        router.push({
            path: '/relogin',
            query: {
                redirect: router.currentRoute.fullPath
            }
        });
    },
    logout({dispatch, commit}) {
        commit('unset');
    },
    handling({dispatch, commit}, response) {
        if (!response.success) {
            if (response.status == 'token-expired') {
                dispatch('expired');
            } else if (response.status == 'token-invalid') {
                dispatch('logout');
            }
        }
    }
}

const getters = {
    user: state => {
        return state.user
    },
    roles: state => {
        return state.roles  
    },
    authenticated: state => {
        return state.authenticated
    },
    expired: state => {
        return state.expired
    },
    userPermission: state => {
        return state.userPermission
    },
    userConfig: state => {
        return state.userConfig
    }
}

const mutations = {
    set(state, auth) {
        state.authenticated = true;
        state.expired = false;
        state.user = auth.user;
        state.roles = auth.roles;
        state.userPermission = auth.userPermission;
        state.userConfig = JSON.parse(auth.user.config)     
        localStorage.setItem('auth', JSON.stringify(state));    
    },
    expired(state) {
        state.expired = true;
        localStorage.setItem('auth', JSON.stringify(state));
    },
    unset(state) {
        state.authenticated = false;
        state.expired = false;
        state.user = {};
        state.roles = {};
        state.userPermission = {};
        state.userConfig = {}
        localStorage.removeItem('auth');
        authHeader.unset();
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}