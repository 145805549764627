import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import DashboardLayout2 from '@/views/Layout/DashboardLayout2.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import Translate from '@/helpers/translate';

const Dashboard = () => import( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
//const Login = () => import(/* webpackChunkName: "dashboard" */ '@/views/Login.vue');

const MaterialType = () => import('@/views/Master/MaterialType.vue');
const MaterialGroup = () => import('@/views/Master/MaterialGroup.vue');
const ServiceCategory = () => import('@/views/Master/ServiceCategory.vue');
const ServiceGroup = () => import('@/views/Master/ServiceGroup.vue');
const HoldingCode = () => import('@/views/Master/HoldingCode.vue');
const CompanyCode = () => import('@/views/Master/CompanyCode.vue');
const PlantCode = () => import('@/views/Master/PlantCode.vue');
const PlantSp = () => import('@/views/Master/PlantSp.vue');
const LocationCode = () => import('@/views/Master/LocationCode.vue');
const ValuationClass = () => import('@/views/Master/ValuationClass.vue');
const CatalogStatus = () => import('@/views/Master/CatalogStatus.vue');
const CatalogType = () => import('@/views/Master/CatalogType.vue');
const EquipmentCode = () => import('@/views/Master/EquipmentCode.vue');
const ManrefType = () => import('@/views/Master/ManrefType.vue');
const SourceType = () => import('@/views/Master/SourceType.vue');
const SalesOrganization = () => import('@/views/Master/SalesOrganization.vue');
const DistributionChannel = () => import('@/views/Master/DistributionChannel.vue');
const Warehouse = () => import('@/views/Master/Warehouse.vue');
const AccountAssignmentGroup = () => import('@/views/Master/AccountAssignmentGroup.vue');
const ProductHierarchy = () => import('@/views/Master/ProductHierarchy.vue');
const ProfitCenter = () => import('@/views/Master/ProfitCenter.vue');
const PriceControl = () => import('@/views/Master/PriceControl.vue');
const PurchasingGroup = () => import('@/views/Master/PurchasingGroup.vue');
const AccountCategoryRefference = () => import('@/views/Master/AccountCategoryRefference.vue');
const SpecialProcurementType = () => import('@/views/Master/SpecialProcurementType.vue');
const ProcurementType = () => import('@/views/Master/ProcurementType.vue');
const LoadingGroup = () => import('@/views/Master/LoadingGroup.vue');
const AbcIndicator = () => import('@/views/Master/AbcIndicator.vue');
const MrpController = () => import('@/views/Master/MrpController.vue');
const MrpTypes = () => import('@/views/Master/MrpTypes.vue');
const LotSize = () => import('@/views/Master/LotSize.vue');
const ItemCategoryGroup = () => import('@/views/Master/ItemCategoryGroup.vue');
const TaxClassification = () => import('@/views/Master/TaxClassification.vue');
const Division = () => import('@/views/Master/Division.vue');
const TransportationGroup = () => import('@/views/Master/TransportationGroup.vue');

const StandardItemName = () => import('@/views/Dictionary/StandardItemName.vue');
const CharacteristicItemName = () => import('@/views/Dictionary/CharacteristicItemName.vue');
const CharacteristicMaster = () => import('@/views/Dictionary/CharacteristicMaster.vue');
const UnitOfMeasure = () => import('@/views/Dictionary/UnitOfMeasure.vue');
const Abbreviation = () => import('@/views/Dictionary/Abbreviation.vue');
const Manufacture = () => import('@/views/Dictionary/Manufacture.vue');
const NatoGroupClass = () => import('@/views/Dictionary/NatoGroupClass.vue');
const Mesc = () => import('@/views/Dictionary/Mesc.vue');
const Unspsc = () => import('@/views/Dictionary/Unspsc.vue');
const SmartcatAttachment = () => import('@/views/Dictionary/SmartcatAttachment.vue');
const ScopeofSupply = () => import('@/views/Dictionary/ScopeOfSupply.vue');
const DrawingMaster = () => import('@/views/Dictionary/DrawingMaster.vue');

const Message = () => import('@/views/Message/Message.vue');
const MessageView = () => import('@/views/Message/View.vue');
const Compose = () => import('@/views/Message/Compose.vue');
const Inbox = () => import('@/views/Message/Inbox.vue');
const InboxView = () => import('@/views/Message/InboxView.vue');
const Sent = () => import('@/views/Message/Sent.vue');
const SentView = () => import('@/views/Message/SentView.vue');

const SapConnection = () => import('@/views/SAP/SapConnection.vue');
const MasterDataSync = () => import('@/views/SAP/MasterDataSync.vue');
const SapSyncTask = () => import('@/views/SAP/SapSyncTask.vue');
const SapSync = () => import('@/views/SAP/SapSync.vue');
const SapSyncMaster = () => import('@/views/SAP/SapSyncMaster.vue');
const SapSyncParams = () => import('@/views/SAP/SapSyncParams.vue');
const SapSyncParamMapping = () => import('@/views/SAP/SapSyncParamMapping.vue');
const SapSyncMenus = () => import('@/views/SAP/SapSyncMenus.vue');

const ManageAccount = () => import('@/views/Manage/ManageAccount.vue');
const ManageAccountAdd = () => import('@/views/Manage/ManageAccountAdd.vue');
const ManageAccountEdit = () => import('@/views/Manage/ManageAccountEdit.vue');
const Jabatan = () => import('@/views/Manage/Jabatan.vue');
const ManageRole = () => import('@/views/Manage/ManageRole.vue');
const ManageRoleAdd = () => import('@/views/Manage/ManageRoleAdd.vue');
const ManageRoleEdit = () => import('@/views/Manage/ManageRoleEdit.vue');
const ManagePermission = () => import('@/views/Manage/ManagePermission.vue');
const Department = () => import('@/views/Manage/Department.vue');
const Section = () => import('@/views/Manage/Section.vue');
const SubSection = () => import('@/views/Manage/SubSection.vue');

const ManageMenu = () => import('@/views/Setting/ManageMenu.vue');
const ManageSubMenu = () => import('@/views/Setting/ManageSubMenu.vue');
const MailConfiguration = () => import('@/views/Setting/MailConfiguration.vue');
const Notification = () => import('@/views/Setting/Notification.vue');
const McrStatus = () => import('@/views/Setting/McrStatus.vue');
const Faq = () => import('@/views/Setting/Faq.vue');
const FaqDetail = () => import('@/views/Setting/FaqDetail.vue');
const DatabaseBackup = () => import('@/views/Setting/DatabaseBackup.vue');
const MailTemplates = () => import('@/views/Setting/MailTemplates.vue');
const Tasks = () => import('@/views/Setting/Tasks.vue');
const TaskMenus = () => import('@/views/Setting/TaskMenus.vue');
// const TaskMenus                     = () => import('@/views/Setting/contohDraggable.vue');
const Workflows = () => import('@/views/Setting/Workflows.vue');
const WorkflowsEdit = () => import('@/views/Setting/WorkflowsEdit.vue');
const TableColumn = () => import('@/views/Setting/TableColumn.vue');
const TableObject = () => import('@/views/Setting/TableObject.vue');
const Script = () => import('@/views/Setting/Script.vue');
const WorkbenchConfig = () => import('@/views/Setting/WorkbenchConfig.vue')

const CheckDuplicate = () => import('@/views/Report/CheckDuplicate.vue');
const CheckDuplicateByPartNumberReport = () => import('@/views/Report/CheckDuplicateByPartNumberReport.vue');
const WorkReport = () => import('@/views/Report/WorkReport.vue');
const Export = () => import('@/views/Report/Export.vue');
const Import = () => import('@/views/Report/Import.vue');
const ImportHistory = () => import('@/views/Report/ImportHistory.vue');
const AppAuditing = () => import('@/views/Report/AppAuditing.vue');
const ModelLog = () => import('@/views/Report/ModelLog.vue');
const TransactionLog = () => import('@/views/Report/TransactionLog.vue');
const ActivityLog = () => import('@/views/Report/ActivityLog.vue');
const KpiMcrApproval = () => import('@/views/Report/KpiMcrApproval.vue');
const KpiMcrCreation = () => import('@/views/Report/KpiMcrCreation.vue');
const KpiWorkbench = () => import('@/views/Report/KpiWorkbench');

const Login = () => import('@/views/User/Login.vue');
const Profile = () => import('@/views/User/Profile.vue');
const ChangePassword = () => import('@/views/User/ChangePassword.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');

const MyWork = () => import('@/views/Dashboard/MyWork.vue');
const MyTask = () => import('@/views/Dashboard/MyTask.vue');
const MyTaskShow = () => import('@/views/Dashboard/MyTaskShow.vue');
const MyTaskClosed = () => import('@/views/Dashboard/MyTaskClosed.vue');
const MyNotification = () => import('@/views/Dashboard/MyNotification.vue');
const SearchMaterial = () => import('@/views/GeneralLayout/SearchMaterial.vue');
const SearchMaterialSearch = () => import('@/views/GeneralLayout/SearchMaterialSearch.vue');
const SearchService = () => import('@/views/GeneralLayout/SearchService.vue');
const SearchServiceSearch = () => import('@/views/GeneralLayout/SearchServiceSearch.vue');
const SearchCharacteristic = () => import('@/views/GeneralLayout/SearchCharacteristic.vue');

// MATERIAL CATALOG REQUEST

const MaterialRequest = () => import('@/views/Material/MaterialRequest.vue');

const DraftListDetail = () => import('@/views/Material/DraftListDetail.vue');
const DraftListEdit = () => import('@/views/Material/DraftListEdit.vue');

const DraftListEditA1 = () => import('@/views/Material/DraftListEditA1.vue');
const DraftListEditA2 = () => import('@/views/Material/DraftListEditA2.vue');
const DraftListEditA3 = () => import('@/views/Material/DraftListEditA3.vue');
const DraftListEditA4 = () => import('@/views/Material/DraftListEditA4.vue');
const DraftListEditB1 = () => import('@/views/Material/DraftListEditB1.vue');
const DraftListEditB2 = () => import('@/views/Material/DraftListEditB2.vue');
const DraftListEditB3 = () => import('@/views/Material/DraftListEditB3.vue');
const DraftListEditB4 = () => import('@/views/Material/DraftListEditB4.vue');
const DraftListEditB5 = () => import('@/views/Material/DraftListEditB5.vue');

const ApprovalPageB2 = () => import('@/views/Material/ApprovalPageB2.vue');
const ApprovalPageB4 = () => import('@/views/Material/ApprovalPageB4.vue');



// MATERIAL CATALOG REQUEST - DAP
const DraftList = () => import('@/views/Material/DraftList.vue');
const McrForm = () => import('@/views/Material/McrForm.vue');
const McrFormDetail = () => import('@/views/Material/McrFormDetail.vue');
const McrFormApproval = () => import('@/views/Material/McrFormApproval.vue');

const ApprovalPage = () => import('@/views/Material/ApprovalPage.vue');

const WorkBenchMaterial = () => import('@/views/GeneralLayout/WorkBench.vue')
// const WorkBenchApproval = () =>  import('@/views/GeneralLayout/WorkBenchApproval.vue')
const Summary = () => import('@/views/Dashboard/Summary.vue')

const BomMaster = () => import('@/views/Bom/BillOfMaterial')
const BomTypeCharacteristic = () => import('@/views/Bom/BomTypeCharacteristic');
const BomCharacteristic = () => import('@/views/Bom/BomCharacteristic');

const Release = () => import("@/views/Config/Release");


// RCS 
const ABCCriteria = () => import("@/views/RCS/ABC/criteria.vue")
const TransactionHistory = () => import("@/views/RCS/TransactionHistory.vue");
const TransactionHistoryPivot = () => import("@/views/RCS/TransactionHistoryPivot.vue");
const ABCServiceLevel = () => import("@/views/RCS/ABC/ServiceLevel.vue");
const Test = () => import('@/views/Test.vue');
const ABCAnalysis = () => import("@/views/RCS/ABC/analysis.vue");
const GoodIssued = () => import("@/views/RCS/GoodIssued.vue");
const GoodIssuedPivot = () => import("@/views/RCS/GoodIssuedPivot.vue");

const master = {
    path: '/master',
    component: DashboardLayout,
    redirect: '/master',
    name: 'General Master Data',
    children: [{
        path: 'manufacturer',
        name: 'Manufacturer',
        component: Manufacture
    },
    {
        path: 'material-type',
        name: 'Material Type',
        component: MaterialType,
        // meta: {
        //     title: Translate.tt('material_type')
        // }
    },
    {
        path: 'material-group',
        name: 'Material Group',
        component: MaterialGroup
    },
    // {
    //     path: 'service-category',
    //     name: 'Service Category',
    //     component:  ServiceCategory
    // },
    // {
    //     path: 'service-group',
    //     name: 'Service Group',
    //     component:  ServiceGroup
    // },
    {
        path: 'unit-of-measure',
        name: 'Unit of Measure',
        component: UnitOfMeasure
    },
    {
        path: 'holding-code',
        name: 'Holding Code',
        component: HoldingCode
    },
    {
        path: 'company-code',
        name: 'Company Code',
        component: CompanyCode
    },
    {
        path: 'plant-code',
        name: 'Plant Code',
        component: PlantCode
    },
    {
        path: 'plant-sp',
        name: 'Plant Sp',
        component: PlantSp
    },
    {
        path: 'location-code',
        name: 'Location Code',
        component: LocationCode
    },
    {
        path: 'valuation-class',
        name: 'Valuation Class',
        component: ValuationClass
    },
    {
        path: 'catalog-status',
        name: 'Catalog Status',
        component: CatalogStatus
    },
    {
        path: 'catalog-type',
        name: 'Catalog Type',
        component: CatalogType
    },
    {
        path: 'manref-type',
        name: 'Manref Type',
        component: ManrefType
    },
    {
        path: 'source-type',
        name: 'Source Type',
        component: SourceType
    },
    {
        path: 'sales-organization',
        name: 'Sales Organization',
        component: SalesOrganization
    },
    {
        path: 'distribution-channel',
        name: 'Distribution Channel',
        component: DistributionChannel
    },
    {
        path: 'warehouse',
        name: 'Warehouse',
        component: Warehouse
    },
    {
        path: 'account-assignment-group',
        name: 'Account Assignment Group',
        component: AccountAssignmentGroup
    },
    {
        path: 'product-hierarchy',
        name: 'Product Hierarchy',
        component: ProductHierarchy
    },
    {
        path: 'profit-center',
        name: 'Profit Center',
        component: ProfitCenter
    },
    {
        path: 'price-control',
        name: 'Price Control',
        component: PriceControl
    },
    {
        path: 'purchasing-group',
        name: 'Purchasing Group',
        component: PurchasingGroup
    },
    {
        path: 'account-category-refference',
        name: 'Account Category Refference',
        component: AccountCategoryRefference
    },
    {
        path: 'special-procurement-type',
        name: 'Special Procurement Type',
        component: SpecialProcurementType
    },
    {
        path: 'procurement-type',
        name: 'Procurement Type',
        component: ProcurementType
    },
    {
        path: 'loading-group',
        name: 'Loading Group',
        component: LoadingGroup
    },
    {
        path: 'abc-indicator',
        name: 'ABC Indicator',
        component: AbcIndicator
    },
    {
        path: 'mrp-controller',
        name: 'MRP Controller',
        component: MrpController
    },
    {
        path: 'mrp-types',
        name: 'MRP Types',
        component: MrpTypes
    },
    {
        path: 'lot-size',
        name: 'Lot Size',
        component: LotSize
    },
    {
        path: 'item-category-group',
        name: 'Item Category Group',
        component: ItemCategoryGroup
    },
    {
        path: 'tax-classification',
        name: 'Tax Classification',
        component: TaxClassification
    },
    {
        path: 'division',
        name: 'Division',
        component: Division
    },
    {
        path: 'transportation-group',
        name: 'Transportation Group',
        component: TransportationGroup
    },


    ]
};

const material = {
    path: '/material',
    component: DashboardLayout,
    redirect: '/material',
    name: 'Material & Service Catalog Request',
    children: [{
        path: 'approval-page/:mcr_code/:token',
        name: 'Approval Page',
        component: ApprovalPage
    },
    {
        path: 'draft-list',
        name: 'Draft List',
        component: DraftList
    },
    {
        path: 'draft-list-detail',
        name: 'Draft List Detail',
        component: DraftListDetail
    },
    {
        path: 'draft-list-edit/:mcr_code/:token',
        name: 'Draft List Edit',
        component: DraftListEdit
    },
    //menu per mcr
    //A1
    {
        path: 'draft-list-edit-A1/:mcr_code/:token/:edit',
        name: 'Draft List Edit A1',
        component: DraftListEditA1
    },
    {
        path: 'draft-list-detail-A1/:mcr_code/:token/:view',
        name: 'Draft List Detail A1',
        component: DraftListEditA1
    },
    //A2
    {
        path: 'draft-list-edit-A2/:mcr_code/:token/:edit',
        name: 'Draft List Edit A2',
        component: DraftListEditA2
    },
    {
        path: 'draft-list-detail-A2/:mcr_code/:token/:view',
        name: 'Draft List Detail A2',
        component: DraftListEditA2
    },
    //A3
    {
        path: 'draft-list-edit-A3/:mcr_code/:token/:edit',
        name: 'Draft List Edit A3',
        component: DraftListEditA3
    },
    {
        path: 'draft-list-detail-A3/:mcr_code/:token/:view',
        name: 'Draft List Detail A3',
        component: DraftListEditA3
    },
    //A4
    {
        path: 'draft-list-edit-A4/:mcr_code/:token/:edit',
        name: 'Draft List Edit A4',
        component: DraftListEditA4
    },
    {
        path: 'draft-list-detail-A4/:mcr_code/:token/:view',
        name: 'Draft List Detail A4',
        component: DraftListEditA4
    },
    //B1
    {
        path: 'draft-list-edit-B1/:mcr_code/:token/:edit',
        name: 'Draft List Edit B1',
        component: DraftListEditB1
    },
    {
        path: 'draft-list-detail-B1/:mcr_code/:token/:view',
        name: 'Draft List Detail B1',
        component: DraftListEditB1
    },
    //B2
    {
        path: 'draft-list-edit-B2/:mcr_code/:token/:edit',
        name: 'Draft List Edit B2',
        component: DraftListEditB2
    },
    {
        path: 'draft-list-detail-B2/:mcr_code/:token/:view',
        name: 'Draft List Detail B2',
        component: DraftListEditB2
    },
    //B3
    {
        path: 'draft-list-edit-B3/:mcr_code/:token/:edit',
        name: 'Draft List Edit B3',
        component: DraftListEditB3
    },
    {
        path: 'draft-list-detail-B3/:mcr_code/:token/:view',
        name: 'Draft List Detail B3',
        component: DraftListEditB3
    },
    //B4
    {
        path: 'draft-list-edit-B4/:mcr_code/:token/:edit',
        name: 'Draft List Edit B4',
        component: DraftListEditB4
    },
    {
        path: 'draft-list-detail-B4/:mcr_code/:token/:view',
        name: 'Draft List Detail B4',
        component: DraftListEditB4
    },

    //B5
    {
        path: 'draft-list-edit-B5/:mcr_code/:token/:edit',
        name: 'Draft List Edit B5',
        component: DraftListEditB5
    },
    {
        path: 'draft-list-detail-B5/:mcr_code/:token/:view',
        name: 'Draft List Detail B5',
        component: DraftListEditB5
    },

    //end menu per mcr
    {
        path: 'material-request',
        name: 'Material Request',
        component: MaterialRequest
    },

    // MCR Approval Page
    {
        path: 'aproval-page-B2/:mcr/:token/:itm_code',
        name: 'Aproval Page B2',
        component: ApprovalPageB2
    },
    {
        path: 'aproval-page-B4/:mcr/:token/:itm_code',
        name: 'Aproval Page B4',
        component: ApprovalPageB4
    },

    // DAP MATERIAL CATALOG REQUEST
    {
        path: 'mcr-form/:mcr_code/:token/:type',
        name: 'MCR Form',
        component: McrForm
    },
    {
        path: 'mcr-form-add/:mcr_type/:mcr_code/:token/:type',
        name: 'MCR Form Detail',
        component: McrFormDetail
    },
    {
        path: 'mcr-form-detail/:mcr_type/:mcr_code/:mcr_item_code/:token/:type',
        name: 'MCR Form Detail',
        component: McrFormDetail
    },
    {
        path: 'mcr-form-approval-process/:mcr_code/:mcr_item_code/:token/:type/:mcr_type',
        name: 'MCR Form Approval',
        component: McrFormApproval
    },
    {
        path: 'test',
        name: 'Test',
        component: Test
    }
    ]
};

const dictionary = {
    path: '/dictionary',
    component: DashboardLayout,
    redirect: '/dictionary',
    name: 'Dictionary',
    children: [{
        path: 'standard-item-name',
        name: 'Standard Item Name',
        component: StandardItemName
    },
    {
        path: 'characteristic-item-name',
        name: 'Characteristic Item Name',
        component: CharacteristicItemName
    },
    {
        path: 'characteristic-master',
        name: 'Characteristic Master',
        component: CharacteristicMaster
    },
    {
        path: 'abbreviation',
        name: 'Abbreviation',
        component: Abbreviation
    },
    {
        path: 'nato-group-class',
        name: 'Nato Group Class',
        component: NatoGroupClass
    },
    {
        path: 'mesc',
        name: 'Mesc',
        component: Mesc
    },
    {
        path: 'unspsc',
        name: 'Unspsc',
        component: Unspsc
    },
    {
        path: 'smartcat-attachment',
        name: 'SmartCat Attachment',
        component: SmartcatAttachment
    },
    {
        path: 'scope-of-supply',
        name: 'Scope Of Supply',
        component: ScopeofSupply
    },
    {
        path: 'equipment',
        name: 'Equipment',
        component: EquipmentCode
    },
    {
        path: 'drawing-master',
        name: 'Drawing Master',
        component: DrawingMaster
    },
    ]
};

const message = {
    path: '/message',
    component: DashboardLayout,
    name: 'Message',
    children: [{
        path: '/message',
        name: 'Message List',
        component: Message
    },
    {
        path: '/message/view/:id',
        name: 'MessageView',
        component: MessageView
    }
    ]
}

/*const message = {
    path: '/message',
    component: DashboardLayout,
    redirect: '/message',
    name: 'Message',
    children: [
        {
            path: 'compose',
            name: 'Compose',
            component: Compose
        },
        {
            path: 'inbox',
            name: 'Inbox',
            component: Inbox
        },
        {
            path: 'inbox-view',
            name: 'Inbox View',
            component: InboxView
        },
        {
            path: 'sent',
            name: 'Sent',
            component: Sent
        },
        {
            path: 'sent-view',
            name: 'Sent View',
            component: SentView
        },
    ]
};*/

const sap = {
    path: '/sap',
    component: DashboardLayout,
    redirect: '/sap',
    name: 'SAP',
    children: [{
        path: 'sap-connection',
        name: 'SAP Connection',
        component: SapConnection
    },
    {
        path: 'sap-sync',
        name: 'SAP Sync',
        component: SapSync
    },
    {
        path: 'sap-sync-task',
        name: 'SAP Sync Task',
        component: SapSyncTask
    },
    {
        path: 'sap-sync-master',
        name: 'SAP Sync Master',
        component: SapSyncMaster
    },
    {
        path: 'sap-sync-params/:id',
        name: 'SAP Sync Params',
        component: SapSyncParams
    },
    {
        path: 'sap-sync-param-mapping/:id',
        name: 'SAP Sync Param Mapping',
        component: SapSyncParamMapping
    },
    {
        path: 'sap-sync-menus',
        name: 'SAP Sync Menus',
        component: SapSyncMenus
    },
    ]
};

const manage = {
    path: '/manage',
    component: DashboardLayout,
    redirect: '/manage',
    name: 'Manage Account',
    children: [{
        path: 'manage-account',
        name: 'Account List',
        component: ManageAccount
    },
    {
        path: 'manage-account-add',
        name: 'Manage Account Add',
        component: ManageAccountAdd
    },
    {
        path: 'manage-account-edit',
        name: 'Manage Account Edit',
        component: ManageAccountEdit
    },
    {
        path: 'jabatan',
        name: 'Jabatan',
        component: Jabatan
    },
    {
        path: 'manage-role',
        name: 'Manage Role',
        component: ManageRole
    },
    {
        path: 'manage-role-add',
        name: 'Manage Role Add',
        component: ManageRoleAdd
    },
    {
        path: 'manage-role-edit/:id',
        name: 'Manage Role Edit',
        component: ManageRoleEdit
    },
    {
        path: 'manage-permission',
        name: 'Manage Permission',
        component: ManagePermission
    },
    {
        path: 'department',
        name: 'Department',
        component: Department
    },
    {
        path: 'section',
        name: 'Section',
        component: Section
    },
    {
        path: 'sub-section',
        name: 'Sub Section',
        component: SubSection
    },
    ]
};

const setting = {
    path: '/setting',
    component: DashboardLayout,
    redirect: '/setting',
    name: 'General Setting',
    children: [{
        path: 'manage-menu',
        name: 'Manage Menu',
        component: ManageMenu
    },
    {
        path: 'manage-sub-menu',
        name: 'Manage Sub Menu',
        component: ManageSubMenu
    },
    {
        path: 'mail-configuration',
        name: 'Mail Configuration',
        component: MailConfiguration
    },
    {
        path: 'notification',
        name: 'Notification',
        component: Notification
    },
    {
        path: 'faq',
        name: 'FAQ',
        component: Faq
    },
    {
        path: 'faq-detail/:id',
        name: 'Detail FAQ',
        component: FaqDetail,
        params: true
    },
    {
        path: 'database-backup',
        name: 'Database Backup',
        component: DatabaseBackup
    },
    {
        path: 'mail-templates',
        name: 'Mail Templates',
        component: MailTemplates
    },
    {
        path: 'tasks',
        name: 'Tasks',
        component: Tasks
    },
    {
        path: 'task-menus',
        name: 'Task Menus',
        component: TaskMenus
    },
    {
        path: 'workflows',
        name: 'Workflows',
        component: Workflows
    },
    {
        path: 'workflows-edit/:id',
        name: 'Workflows Edit',
        component: WorkflowsEdit
    },
    {
        path: 'table-column',
        name: 'Table Column',
        component: TableColumn
    },
    {
        path: 'table-object',
        name: 'Table Object',
        component: TableObject
    },
    {
        path: 'script',
        name: 'Script',
        component: Script
    },
    {
        path: 'workbench-config',
        name: 'Workbench Config',
        component: WorkbenchConfig
    }
    ]
};

const report = {
    path: '/report',
    component: DashboardLayout,
    redirect: '/report',
    name: 'Report',
    children: [{
        path: 'check-duplicate',
        name: 'Check Duplicate',
        component: CheckDuplicate
    },
    {
        path: 'check-duplicate-by-part-number-report',
        name: 'Check Duplicate By Part Number Report',
        component: CheckDuplicateByPartNumberReport
    },
    {
        path: 'work-report',
        name: 'Work Report',
        component: WorkReport
    },
    {
        path: 'export',
        name: 'Export',
        component: Export
    },
    {
        path: 'import',
        name: 'Import',
        component: Release
    },
    {
        path: 'import-history',
        name: 'Import History',
        component: ImportHistory
    },
    {
        path: 'kpi-mcr-approval',
        name: 'KPI MCR Status',
        component: KpiMcrApproval
    },
    {
        path: 'app-auditing',
        name: 'App Auditing',
        component: AppAuditing
    },
    {
        path: 'model-log',
        name: 'Model Log',
        component: ModelLog
    },
    {
        path: 'transaction-log',
        name: 'Transaction Log',
        component: TransactionLog
    },
    {
        path: 'activity-log',
        name: 'Activity Log',
        component: ActivityLog
    },
    {
        path: 'kpi-mcr-approval',
        name: 'KPI MCR Status',
        component: KpiMcrApproval
    },
    {
        path: 'kpi-mcr-creation',
        name: 'KPI MCR Creation',
        component: KpiMcrCreation
    },
    {
        path: 'kpi-workbench',
        name: 'KPI Workbench',
        component: KpiWorkbench
    }
    ]
};

const rcs = {
    path: "/rcs",
    component: DashboardLayout,
    redirect: "/rcs",
    name: "RCS",
    children: [
        {
            path: "abc-criteria",
            name: "ABC Criteria",
            component: ABCCriteria,
        },
        {
            path: "abc-service-level",
            name: "ABC Service Level",
            component: ABCServiceLevel,
        },
        {
            path: "abc-analysis",
            name: "ABC Analysis",
            component: ABCAnalysis,
        },
        {
            path: "transaction-history",
            name: "Transaction History",
            component: TransactionHistory,
        },
        {
            path: "transaction-history-pivot",
            name: "Transaction History Pivot",
            component: TransactionHistoryPivot,
        },
        {
            path: "good-issued",
            name: "Good Issued",
            component: GoodIssued,
        },
        {
            path: "good-issued-pivot",
            name: "Good Issued Pivot",
            component: GoodIssuedPivot,
        },
    ],
};

const login = {
    path: '/user',
    // component: DashboardLayout,
    redirect: '/user',
    name: 'User',
    children: [{
        path: 'login',
        name: 'Login',
        component: Login
    },
    ]
};

const user = {
    path: '/user',
    component: DashboardLayout2,
    redirect: '/user',
    name: 'User',
    children: [{
        path: 'profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: 'change-password',
        name: 'Change Password',
        component: ChangePassword
    },
    ]
};

const dashboard = {
    path: '/dashboard',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard',
    children: [{
        path: 'my-work',
        name: 'My Work',
        component: MyWork
    },
    {
        path: 'my-task',
        name: 'My Task',
        component: MyTask
    },
    {
        path: 'my-task-show/:id',
        name: 'My Task Show',
        component: MyTaskShow
    },
    {
        path: 'my-task-closed/:id',
        name: 'My Task Closed',
        component: MyTaskClosed
    },
    {
        path: 'my-notification',
        name: 'My Notification',
        component: MyNotification
    },
    {
        path: 'summary',
        name: 'Summary',
        component: Summary
    },
    {
        path: '/release',
        name: 'Release',
        component: Release
    },
    ]
};

const general_layout = {
    path: '/general_layout',
    component: DashboardLayout,
    redirect: '/general-layout',
    name: 'General Layout',
    children: [{
        path: 'search-material/:type',
        name: 'Search Material',
        component: SearchMaterial
    },
    {
        path: 'end-user-search-material',
        name: 'Search Material Search',
        component: SearchMaterialSearch
    },
    // {
    //     path: 'search-service',
    //     name: 'Search Service',
    //     component: SearchService
    // },
    // {
    //     path: 'search-service-search',
    //     name: 'Search Service Search',
    //     component: SearchServiceSearch
    // },
    {
        path: 'search-characteristic/:type',
        name: 'Search Characteristic',
        component: SearchCharacteristic
    },
    {
        path: 'search-characteristic-search',
        name: 'Search Characteristic Search',
        component: SearchMaterialSearch
    },
    {
        path: 'workbench',
        name: 'WorkBench',
        component: WorkBenchMaterial
    },
        // {
        //     path: 'workbench-approval',
        //     name: 'Workbench Approval',
        //     component: WorkBenchApproval
        // },
    ]
};

const bom = {
    path: "/bom",
    component: DashboardLayout,
    name: "BOM",
    children: [
        {
            path: "master",
            name: "Bom Master",
            component: BomMaster
        },
        {
            path: "type",
            name: "Bom Type",
            component: BomTypeCharacteristic
        },
        {
            path: "characteristic",
            name: "Bom Characteristic",
            component: BomCharacteristic
        }
    ]
};


const routes = [{
    path: '/',
    redirect: '/dashboard',
    name: 'Dashboard'
},
{
    path: '/login',
    name: 'Login',
    component: Login
},
{
    path: '/relogin',
    name: 'Re-Login',
    component: Login
},
{
    path: '/not-found',
    name: 'Not Found',
    component: NotFound
},
{
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword
},
{
    path: '/reset-password/:token',
    name: 'Reset Password',
    component: ResetPassword
},
{
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [{
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
    },]
},
    master,
    material,
    dictionary,
    message,
    sap,
    manage,
    setting,
    report,
    user,
    dashboard,
    general_layout,
    bom,
    rcs,
];

export default routes;