import Service from '@/helpers/service'

export default {

    index() {
        return Service().get('user-profile');
    }, 
    update(id, params) {
        return Service().post('user-profile/update/'+id, params);
    },    
    change_password(params) {
        return Service().post('change-password', params);
    },
    change_profile_photo(id, params) {
        return Service().post('change-profile-photo/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    get_client(){
        return Service().get('client');
    }
}