<template>
   <div class="row">
        <!-- COLUMN FOR TABLE -->
        <div class="col-6">
		   <div class="card" style="height:735px">
			   <div class="card-body">
				   <tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="card shadow">
						<tab-pane id="check_by_description">
							<span slot="title">{{tt('check_duplicate_by_description')}}</span>
							<el-table height="600px" class="customTable" :data="tableDataDescription" header-row-class-name="thead-light" highlight-current-row @current-change="handleCurrentChange" v-if="!onLoad.table">
								<el-table-column prop="material_number" :label="tt('mat_no')"></el-table-column>
								<el-table-column prop="item_name" :label="tt('item_name')"></el-table-column>
								<!-- <el-table-column prop="long_description" :label="tt('long_description')" width="250"></el-table-column> -->
							</el-table>
						</tab-pane>

						<tab-pane id="check_by_part_number">
							<span slot="title">{{tt('check_duplicate_by_reference')}}</span>
							<el-table height="600px" class="customTable" :data="tableDataPartNumber" header-row-class-name="thead-light" highlight-current-row @current-change="handleCurrentChange" v-if="!onLoad.table">
								<el-table-column prop="material_number" :label="tt('material_number')"></el-table-column>
                <el-table-column prop="manufacture_code" :label="tt('manufacture_code')"></el-table-column>
								<el-table-column prop="manufacture_ref" :label="tt('manufacture_ref')"></el-table-column>
								<!-- <el-table-column prop="spn" :label="tt('spn')" min-width="180"></el-table-column> -->
							</el-table>
						</tab-pane>
				   </tabs>

			   </div>
		   </div>
        </div>
        <!-- COLUMN FOR CLASSIFICATION -->
        <div class="col-6">
            <div class="card pb-4" style="height:735px">
                <div class="card-header pb-4">
                    <div class="row">
                        <div class="col-12 mt-4">
                            <h4>
                                <b>{{ tt('classification')}}</b>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="card-body pb-2" style="overflow-y:scroll">
                    <div  id="tableClassification">
                        <table class="table align-items-center table-flush">
                            <thead class="card-header bg-primary">
                                <tr>
                                <th class="text-white">{{tt('characteristic')}}</th>
                                <th class="text-white">{{tt('value')}}</th>
                                <th class="text-white">{{tt('view')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="showCharValue.onLoading == true">
                                    <td colspan="3" class="text-center"><span><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span></td>
                                </tr>
                                <tr v-for="(dinc, key) in CharValue.cin_code" v-else>
                                    <td>
                                        {{CharValue.characteristic[key]}}
                                        <input disabled type="hidden" v-model="CharValue.cin_code[key]">
                                        <input disabled type="hidden" v-model="CharValue.code[key]">

                                    </td>
                                    <td>
                                        <el-autocomplete 
                                        v-model="CharValue.value[key]"
                                        :trigger-on-focus="false"
                                        size="small"
                                        disabled>
                                        </el-autocomplete>
                                    </td>
                                    <td>
                                        <input disabled type="checkbox" name="check" v-model="CharValue.view[key]" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
             <span  class="dialog-footer float-right">
                <base-button type="primary" @click="cloneData" :disabled="buttonClone == false">
				{{ tt('clone_all_data')}}
				<span v-if="btnClone.onLoading == true"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
			 </base-button>
            </span>
        </div>
    </div>
</template>

<script>
    import checkDuplicate from '@/services/report/checkDuplicate.service.js';
    import Api from '@/helpers/api';
    import WorkbenchMaterial from '@/services/workbench/WorkbenchMaterial.service';

    export default {
      name: "CheckDuplicateWorkbench",
      props: {
        materialNumber: {
            type: String,
            default: '',
            description: 'Material Number Data'
        },
    		clicked: {
    			type: Boolean,
    			default: false,
    			description:"Material Data Clicked"
    		}
      },
      watch : {
          'materialNumber' : function() {
		          this.clicked = false;
              this.get();
      		    this.materialNumberFrom = '';
      		    this.buttonCloneData();
          },
          'materialNumberFrom': function(){
              this.buttonCloneData();
          },
      },
      mounted(){
            this.get()
      },
      data() {
        return {
          buttonClone: false,
      		btnClone: {
      			loading: false
      		},
          tableDataDescription:[],
          tableDataPartNumber:[],
          onLoad: {
            table: true,
            detail: true
          },
          CharValue:{
            id:[],
            cin_code:[],
            characteristic:[],
            value:[],
            view:[],
            multipleChar:[],
            code:[],
          },
          showCharValue:{
              onLoading:false
          },
          materialNumberFrom:'',
          materialNumberTo:'',
          activeName:'check_by_description'
        }
      },
      methods : {
          get(){
              let context = this;
              Api(context, checkDuplicate.checkDuplicateWorkbench(context.materialNumber))
              .onSuccess(function(response){

                  context.tableDataDescription = response.data.data.byDescription;
                  context.tableDataPartNumber = response.data.data.byPartNumber;
                  context.onLoad.table  = false;
                  context.onLoad.detail = false;
              }).onError(function(error){
                  context.tableDataDescription = [];
                  context.tableDataDescription =[];
                  context.onLoad.table  = false;
                  context.onLoad.detail = false;
                  context.CharValue.id             = [];
                  context.CharValue.cin_code       = [];
                  context.CharValue.characteristic = [];
                  context.CharValue.value          = [];
                  context.CharValue.view           = [];
                  context.CharValue.multipleChar   = [];
                  context.CharValue.code           = [];
              }).call();
          },
          handleCurrentChange(val) {
              let context = this;
              context.showCharValue.onLoading = true
              if (val != null) {
                  context.currentRow = val;
                  context.material_number = context.currentRow.material_number;
                  context.materialNumberFrom = context.currentRow.material_number;
                  context.materialNumberTo = context.materialNumber;
              }
                
              context.onLoad.detail = true;

              Api(context, WorkbenchMaterial.getCharacteristic(context.currentRow.material_number))
              .onSuccess(function(response) {
                  // DEVI GET PLANT
                  context.showCharValue.onLoading = false
                  // Get Part Char
                  context.CharValue.id             = [];
                  context.CharValue.cin_code       = [];
                  context.CharValue.characteristic = [];
                  context.CharValue.value          = [];
                  context.CharValue.view           = [];
                  context.CharValue.multipleChar   = [];
                  context.CharValue.code           = [];
                  for(var i=0; i < response.data.data.length;i++){
                      context.CharValue.id[i] = (response.data.data[i].id == null) ? '' : response.data.data[i].id;
                      context.CharValue.cin_code[i] = (response.data.data[i].cin_code == null) ? '' : response.data.data[i].cin_code;
                      context.CharValue.characteristic[i] = (response.data.data[i].characteristic == null) ? '' : response.data.data[i].characteristic;
                      context.CharValue.value[i] = (response.data.data[i].characteristic_value == null) ? '' : response.data.data[i].characteristic_value;
                      context.CharValue.view[i] = (response.data.data[i].view == 'checked') ? true : false;
                      context.CharValue.multipleChar[i] = (response.data.data[i].single_or_multiple == null) ? '' : response.data.data[i].single_or_multiple;
                      context.CharValue.code[i] = (response.data.data[i].code == null) ? '' : response.data.data[i].code;
                  }

                  context.onLoad.detail = false;
              })
              .onError(function(error) {
      				    context.CharValue.id             = [];
      				    context.CharValue.cin_code       = [];
      				    context.CharValue.characteristic = [];
      				    context.CharValue.value          = [];
      				    context.CharValue.view           = [];
      				    context.CharValue.multipleChar   = [];
      				    context.CharValue.code           = [];
      				    context.showCharValue.onLoading = false
    			    }).onFinish(()=>{
                  context.showCharValue.onLoading = false
              }).call()
		      },
          cloneData(){
              let context = this;
              let formData = new FormData();
              this.btnClone.onLoading = true;
              formData.append('material_number_from', this.materialNumberFrom);
              formData.append('material_number_to', this.materialNumberTo);
              Api(context, WorkbenchMaterial.cloneAllData(formData)).onSuccess(function(response){
                  context.$notify({
                      message: context.tt('clone_all_data_success'),                  
                      type: 'success',
                      verticalAlign: "bottom",
                      horizontalAlign: "left"
                  });
                  context.showCopyModal = false;
              }).onError(function(err){
                  context.$notify({
                      message: context.tt('clone_all_data_error'),                  
                      type: 'danger',
                      verticalAlign: "bottom",
                      horizontalAlign: "left"
                  });
              }).onFinish(function(){
                  context.btnClone.onLoading = false;
                  context.get();
                  context.$refs.multipleTable.setCurrentRow(context.currentRow);
              }).call()
          },
          buttonCloneData(){
              if(this.materialNumberFrom !== '' && this.materialNumberTo !== '' && this.clicked === true){
      			    console.log(this.clicked)
      			    this.buttonClone = true;
      		    }
          },
      		handleClick(tab, event) {
      			console.log(tab, event);
      		}
      }
    };
</script>

<style>
  #tableClassification, .table th, .table td{
        padding : 0.1rem
  }
  .customTable td{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
</style>