<template>
    <div>
        <div class="row" v-if="mappingData.loading == false" >
            <div class="col-12">
                <base-button class="float-right mb-1">
                    <span style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;"><i class="fa fa-plus" @click="addMultipleChar()"></i></span>
                </base-button>
            </div>
            <div class="col-12" style="height:500px; overflow-y:auto">
                <table class="table align-items-center table-flush">
                    <thead class="card-header bg-primary">
                        <tr>
                        <th class="text-white">{{tt('characteristic')}}<span class="text-danger">&nbsp;*</span></th>
                        <th class="text-white">{{tt('value')}}<span class="text-danger">&nbsp;*</span></th>
                        <th class="text-white">{{tt('sequence')}}<span class="text-danger">&nbsp;*</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dinc, key) in mappingData.characteristic.cin_code">
                            <td>
                                <el-select v-model="mappingData.characteristic.cin_code[key]" filterable > 
                                    <el-option v-for="cim in characteristicItemName" :key="cim.cin_code" :selected="cim.cin_code == mappingData.characteristic.cin_code[key]" :value="cim.cin_code" :label="cim.characteristic" v-on:click="selectChar(cim.cin_code, cim.characteristic, cim.single_or_multiple)" required>
                                        <!-- {{ ((mappingData.characteristic.cin_code.indexOf(cim.cin_code) != -1) && (cim.single_or_multiple == 1)) ? '': cim.characteristic }} -->
                                    </el-option>
                                </el-select>
                            </td>
                            <td>
                                <el-select v-model="mappingData.characteristic.characteristic_value[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchCin" @focus="getCinCode(mappingData.characteristic.cin_code[key])" @blur="getCinCode(0)" :loading="cinCodeLoading" filterable remote reserve-keyword clearable=""> 
                                    <el-option v-for="cim in charValue" :key="cim.characteristic_value" :value="cim.characteristic_value" :selected="mappingData.characteristic.characteristic_value[key] == cim.characteristic_value" :label="cim.characteristic_value"></el-option>
                                </el-select>
                            </td>
                            <td>
                                <el-input v-model="mappingData.characteristic.sequence[key]" :placeholder="tt('sequence')" type="number" width="20px" min="0"></el-input>
                                <button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;">
                                    <i class="fa fa-minus-square text-danger float-right"  aria-hidden="true" style="font-size: 15px" @click="removeMultipleChar(key)"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <div class="float-right">
                    <base-button type="secondary" @click="close()">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="save()" :disabled="disableForm || mappingData.saveLoading">
                        <span v-if="mappingData.saveLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{tt('save')}}
                        </span>
                    </base-button>
                </div>
            </div>
        </div>
        <page-loading v-else></page-loading>
    </div>
</template>

<script>
import Api from '@/helpers/api';
import standardItemName from '@/services/dictionary/standardItemName.service';
import characteristicItemName from '@/services/dictionary/characteristicItemName.service';

export default{
    name:'mesc-characteristic',
    props:['inc','key_mesc'],
    data(){
        return{
            mappingData:{
                loading :false,
                characteristic:{
                    cin_code:[],
                    characteristic_value:[],
                    sequence:[],
                },
                characteristicItemName:[],
                saveLoading:false,
            },
            charValue:[],
            loadTimeout:null,
            cinCodeSearch:'',
            cinCodeLoading:false,
            disableForm:false,
        }
    }, 
    watch:{
        'key_mesc' : function(){
            this.characteristicItemName = []
            this.get()
        },
        'mappingData.characteristic.cin_code': function(){
            if(this.mappingData.characteristic.cin_code.length < 1){
                this.disableForm = true;
            }else{
                this.disableForm = false;
            }
        }
    },
    methods:{
        selectChar(cin_code, characteristic, multiple){
            if(multiple == 1){
                let index = this.charValue.indexOf({ cin_code: cin_code});
                this.charValue.slice(index)
                console.log(index)
            }
        },
        get(){
            let context = this;
            this.mappingData.loading = true;
            Api(context, standardItemName.get_char(this.inc, this.key_mesc)).onSuccess((response) =>{
                let chars = response.data.data.mescCharacteristic;
                context.characteristicItemName = response.data.data.characteristic
                if(chars.length == 0){
                    //     for(var i = 0; i < 1; i++){
                    //     context.mappingData.characteristic.cin_code[i] =''
                    //     context.mappingData.characteristic.characteristic_value[i] = '' 
                    //     context.mappingData.characteristic.multiple[i] = ''
                    // }
                    context.mappingData.characteristic.cin_code = []
                    context.mappingData.characteristic.characteristic_value = []
                    context.mappingData.characteristic.sequence = []
                }
                context.mappingData.characteristic.cin_code = []
                context.mappingData.characteristic.characteristic_value = []
                context.mappingData.characteristic.sequence = []
                for(var i = 0; i < chars.length; i++){
                    context.mappingData.characteristic.cin_code[i] = (chars[i].cin_code == null) ? '' : chars[i].cin_code
                    context.mappingData.characteristic.characteristic_value[i] = (chars[i].characteristic_value == null) ? '' : chars[i].characteristic_value 
                    context.mappingData.characteristic.sequence[i] = (chars[i].sequence == null) ? 1 : chars[i].sequence 
                }
                context.mappingData.loading = false;
            }).onError((error) =>{
                context.mappingData.characteristic.cin_code = []
                context.mappingData.characteristic.characteristic_value = []
                context.mappingData.characteristic.sequence = []
                context.characteristicItemName = [];
                context.mappingData.loading = false;
            }).call()
        },
        querySearchCin(query) {
            if (query.length > 1) {
                clearTimeout(this.loadTimeout);   
                this.cinCodeLoading = true;          
                let context = this;   
                this.charValue = [];
                this.loadTimeout = setTimeout(() => {
                   Api(context, characteristicItemName.get_characteristic_value(null, this.cinCodeSearch, {per_page: 'none', search_char_value: query}))
                    .onSuccess(function(response) {
                        context.charValue = response.data.data.data.data;
                        context.cinCodeLoading = false;
                    }).onError(function(error) {
                        context.charValue = [];
                        context.cinCodeLoading = false;
                    }).call();            
                })
            }
        },
        addMultipleChar() {
            this.mappingData.characteristic.cin_code.push('')
            this.mappingData.characteristic.characteristic_value.push('')
            this.mappingData.characteristic.sequence.push('')
        },
        removeMultipleChar(key) {
            this.mappingData.characteristic.cin_code.splice(key,1)
            this.mappingData.characteristic.characteristic_value.splice(key,1)
            this.mappingData.characteristic.sequence.splice(key,1)
        },
        save(){
            let context = this;
            let formData = new FormData();
            this.mappingData.saveLoading = true;

            for(var i = 0; i < this.mappingData.characteristic.cin_code.length; i++){
                if(this.mappingData.characteristic.cin_code[i] != ''){
                    if(this.mappingData.characteristic.characteristic_value[i] != ''){
                        if(this.mappingData.characteristic.sequence[i] == ''){
                            formData.append('sequence['+i+']', 0)
                        }else{
                            formData.append('sequence['+i+']', this.mappingData.characteristic.sequence[i])
                        }
                    }
                    else{
                        if(this.mappingData.characteristic.sequence[i] == '' || this.mappingData.characteristic.sequence[i] == 0){
                            this.$notify({
                                message: 'Sequence Is Empty',
                                type: 'danger',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            this.mappingData.saveLoading = false;
                            return;
                        }else{
                            formData.append('sequence['+i+']', this.mappingData.characteristic.sequence[i]);
                        }
                    }              
                    formData.append('characteristic_value['+i+']', this.mappingData.characteristic.characteristic_value[i]);
                    formData.append('cin_code['+i+']', this.mappingData.characteristic.cin_code[i]);  
                    
                }else{
                    this.$notify({
                        message: 'Characteristic is Empty',
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    this.mappingData.saveLoading = false;
                    return;
                }
            }
            formData.append('key_mesc', this.key_mesc)
            Api(context, standardItemName.save_char_mesc(this.inc, formData)).onSuccess((response) =>{
                context.mappingData.saveLoading = false;
                 context.$notify({
                    message: response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                });
            }).onError((error) =>{
                context.$notify({
                    message: error.response.data.message,
                    type: 'danger',
                    verticalAlign: 'bottom', 
                    horizontalAlign: 'left'
                }); 
                context.mappingData.saveLoading = false;
            }).call()
        },
        getCinCode(cin_code){
            if(cin_code == 0){
                this.cinCodeSearch = ''
            }else{
                this.charValue = [];
                this.cinCodeSearch = cin_code
            }
        },
        close(){
            this.$emit('close', false)
        }
    }
}
</script>
