import Service from '@/helpers/service'

export default {

    login(username, password) {
        return Service().post('auth/login', {username: username, password: password});
    },
    userProfile() {
        return Service().get('user-profile');
    },
    logout() {
        return Service().get('/logout');
    },
    forgotPassword(email) {
        return Service().post('/password/reset-request', {email: email});
    },
    resetPassword(email, password, password_confirmation, token) {
        return Service().post('/password/reset?0='+ token , {email: email, password: password, password_confirmation: password_confirmation, token: token});
    },
}