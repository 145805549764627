export default {
    'confirm_delete': 'Are you sure to delete this data?',
    'success_delete': 'Successed delete data',
    'error_delete': 'Failed delete data',

    'confirm_update': 'Are you sure to update this data?',
    'success_update': 'Successed update data',
    'error_update': 'Failed update data',

    'confirm_close': 'Are you sure to close this data?',
    'success_close': 'Successed close data',
    'error_close': 'Failed close data',

    'confirm_send': 'Are you sure to send this data?',
    'success_send': 'Successed send data',
    'error_send': 'Failed send data',

    'confirm_default': 'Are you sure to default this data?',
    'success_default': 'Successed default data',
    'error_default': 'Failed default data',

    'confirm_synchron': 'Are you sure to synchron this data?',
    'success_synchron': 'Successed synchron data',
    'error_synchron': 'Failed synchron data',

    'confirm_process': 'Are you sure to process this data?',

    'confirm_clone': 'Are you sure to clone this data?',

    'success_login': 'Welcome :name',
    'error_username_password_required': 'Username and Password can\'t empty',
    'error_unauthorization': 'You don\'t have permission to this page',

    'home': 'Home',

    'login': 'Login',
    'please_login': 'Please Login',
    'username': 'Username',
    'password': 'Password',
    'forgot_password': 'Forgot Password ?',
    'reset_password': 'Reset Password',
    'please_wait': 'Please Wait',

    'material_type': 'Material Type',
    'm_type': 'M Type',
    'maintenance_status': 'Maintenance Status',
    'account_category_refference': 'Account Category Refference',
    'add_material_type': 'Add Material Type',
    'edit_material_type': 'Edit Material Type',
    'relation': 'Relation',
    'create_relation': 'Create Relation',
    'search_material_type': 'Search Material Type',
    'choose_account_catalog_refference': 'Choose Account Catalog Refference',
    'choose_account_category_refference': 'Choose Account Category Refference',
    'choose_price_control': 'Choose Price Control',

    'search_material_group': 'Search Material Group',
    'purchasing_group': 'Purchasing Group',
    'add_material_group': 'Add Material Group',
    'edit_material_group': 'Edit Material Group',
    'choose_holding': 'Choose Holding',
    'choose_client': 'Choose Client',
    'choose_purchasing_group': 'Choose Purchasing Group',

    'search_service_category': 'Search Service Category',
    'add_service_category': 'Add Service Category',
    'edit_service_category': 'Edit Service Category',

    'search_service_group': 'Search Service Group',
    'add_service_group': 'Add Service Group',
    'edit_service_group': 'Edit Service Group',

    'client': 'Client',
    'search_client': 'Search Client',
    'client_code': 'Client Code',
    'client_name': 'Client Name',
    'add_holding_code': 'Add Holding Code',
    'edit_holding_code': 'Edit Holding Code',

    'company_code': 'Company Code',
    'search_company_code': 'Search Company Code',
    'company_description': 'Company Description',
    'add_company_code': 'Add Company Code',
    'edit_company_code': 'Edit Company Code',

    'search_plant_code': 'Search Plant Code',
    'city': 'City',
    'address': 'Address',
    'po_box': 'PO Box',
    'postal_code': 'Postal Code',
    'add_plant_code': 'Add Plant Code',
    'edit_plant_code': 'Edit Plant Code',
    'choose_company': 'Choose Company',
    'choose_profit_center': 'Choose Profit Center',

    'search_plant_sp': 'Search Plant SP',
    'add_plant_sp': 'Add Plant SP',
    'edit_plant_sp': 'Edit Plant SP',

    'search_location_code': 'Search Location Code',
    'location_description': 'Location Description',
    'add_location_code': 'Add Location Code',
    'edit_location_code': 'Edit Location Code',
    'choose_plant': 'Choose Plant',
    'choose_material_group': 'Choose Material Group',
    'choose_manufacture': 'Choose Manufacture',

    'search_valuation_class': 'Search Valuation Class',
    'add_valuation_class': 'Add Valuation Class',
    'edit_valuation_class': 'Edit Valuation Class',

    'search_catalog_status': 'Search Catalog Status',
    'add_catalog_status': 'Add Catalog Status',
    'edit_catalog_status': 'Edit Catalog Status',
    'choose_type': 'Choose Type',

    'search_catalog_type': 'Search Catalog Type',
    'add_catalog_type': 'Add Catalog Type',
    'edit_catalog_type': 'Edit Catalog Type',
    'choose_catatalog_type': 'Choose Catalog Type',

    'choose_catalog_status': 'Choose Catalog Status',

    'search_equipment_code': 'Search Equipment Code',
    'add_equipment_code': 'Add Equipment Code',
    'edit_equipment_code': 'Edit Equipment Code',
    'equipment_description': 'Equipment Description',
    'serial_number': 'Serial Number',
    'additional_data': 'Additional Data',
    'manufacturer': 'Manufacturer',

    'search_manref_type': 'Search Manref Type',
    'add_manref_type': 'Add Manref Type',
    'edit_manref_type': 'Edit Manref Type',

    'search_source_type': 'Search Source Type',
    'choose_source_type': 'Choose Source Type',
    'add_source_type': 'Add Source Type',
    'edit_source_type': 'Edit Source Type',

    'search_sales_organization': 'Search Sales Organization',
    'add_sales_organization': 'Add Sales Organization',
    'edit_sales_organization': 'Edit Sales Organization',
    'sales_organization': 'Sales Organization',

    'search_distribution_channel': 'Search Distribution Channel',
    'add_distribution_channel': 'Add Distribution Channel',
    'edit_distribution_channel': 'Edit Distribution Channel',
    'distribution_channel': 'Distribution Channel',
    'sales_unit': 'Sales Unit',
    'distr_channel': 'Distr Channel',

    'search_warehouse': 'Search Warehouse',
    'add_warehouse': 'Add Warehouse',
    'edit_warehouse': 'Edit Warehouse',
    'warehouse': 'Warehouse',
    'warehouse_number': 'Warehouse No',

    'search_account_assignment_group': 'Search Account Assignment Group',
    'add_account_assignment_group': 'Add Account Assignment Group',
    'edit_account_assignment_group': 'Edit Account Assignment Group',

    'search_product_hierarchy': 'Search Product Hierarchy',
    'add_product_hierarchy': 'Add Product Hierarchy',
    'edit_product_hierarchy': 'Edit Product Hierarchy',
    'level': 'Level',

    'search_profit_center': 'Search Profit Center',
    'add_profit_center': 'Add Profit Center',
    'edit_profit_center': 'Edit Profit Center',

    'search_price_control': 'Search Price Control',
    'add_price_control': 'Add Price Control',
    'edit_price_control': 'Edit Price Control',

    'search_purchasing_group': 'Search Purchasing Group',
    'add_purchasing_group': 'Add Purchasing Group',
    'edit_purchasing_group': 'Edit Purchasing Group',

    'search_account_category_refference': 'Search Account Category Refference',
    'add_account_category_refference': 'Add Account Category Refference',
    'edit_account_category_refference': 'Edit Account Category Refference',

    'approval_list': 'Approval List',
    'approval_page': 'Approval Page',
    'revision': 'Revision',
    'created_by': 'Created By',
    'total_item': 'Total Item',
    'workflow_status': 'Workflow Status',
    'approval': 'Approval',
    'approval_page': 'Approval Page',
    'mcr_information': 'MCR Information',
    'mcr_item': 'MCR Item',
    'mcr_item_note': 'MCR Item Note',
    'tracking': 'Tracking',
    'approve': 'Approve',
    'approval_tracking': 'Approval Tracking',
    'approver': 'Approver',
    'reject_mcr': 'Reject MCR',

    'draft_list': 'Draft List',
    'basic_data_&_specification': 'Basic Data & Specification',
    'fabric_or_non_fabrication': ' Fabric/Non Fabrication',
    'bom_or_non_bom': 'Bom/Non Bom Equipment',
    'specification': 'Specification',
    'manufacturer_part_number': 'Manufacturer Part Number',
    'manufacturer_code': 'Manufacturer Code',
    'mpn': 'MPN',
    'mcr_subject': 'MCR Subject',
    'edit_subject': 'Edit Subject',
    'reason': 'Reason',
    'consolidate_from': 'Consolidate From',
    'consolidate_to': 'Consolidate To',
    'equipment_&_quantity_install': 'Equipment & Quantity Install',
    'qty_install': 'Qty Install',
    'attachment_document': 'Attachment Document',
    'up_attachment_document': 'ATTACHMENT DOCUMENT',
    'attachment': 'Attachment',
    'up_attachment': 'ATTACHMENT',
    'file_type': 'File Type',
    'choose_item_name': 'Choose Item Name',
    'choose_item_type': 'Choose Item Type',
    'choose_fabric_or_non_fabrication': 'Choose Fabric/Non Fabrication',
    'choose_bom_or_non_bom': 'Choose Bom/Non Bom',
    'change_material': 'Change Material',
    'client_level': 'Client Level',
    'plant_level': 'Plant Level',
    'minimum_stock': 'Minimum Stock',
    'mrp_type': 'MRP Type',
    'reorder_point': 'Reorder Point',
    'maximum_stock': 'Maximum Stock',
    'extend_material': 'Extend Material',
    'deletion_flag_material': 'Deletion Flag Material',
    'valuation_type': 'Valuation Type',
    'valuation_area': 'Valuation Area',
    'choose_valuation_area': 'Choose Valuation Area',
    'val_type': 'Val Type',
    'storage_type': 'Storage Type',
    'value_view': 'Value View',
    'po_text_memo': 'PO Text Memo',
    'sales': 'Sales',
    'up_sales': 'SALES',
    'sales_org': 'Sales Org',
    'division': 'Division',
    'search_division': 'Search Division',
    'search_tax_class': 'Search Tax Classification',
    'search_gen_item_cat_grp': 'Gen. Item Cat. Grp.',
    'search_item_cat_grp': 'Search Item Category Group',
    'search_trans_group': 'Search Transportation Group',
    'tax_cat': 'Tax Cat',
    'tax_class': 'Tax Class',
    'gen_item_cat_grp': 'Gen Item Cat Grp',
    'account_assign_grp': 'Acc Assign Grp',
    'item_cat_grp': 'Item Cat Grp',
    'trans_group': 'Trans Group',
    'loading_group': 'Loading Group',
    'mrp': 'MRP',
    'mrp_controller': 'MRP Controller',
    'search_mrp_controller': 'Search MRP Controller',
    'mrp_types': 'MRP Types',
    'search_mrp_types': 'Search MRP Types',
    'lot_size': 'Lot Size',
    'max_stock': 'Max Stock',
    'fixed_lot': 'Fixed Lot',
    'ord_costs': 'Ord Costs',
    'search_lot_size': 'Ord Costs',
    'min': 'Min',
    'max': 'Max',
    'procurement_type': 'Procurement Type',
    'special_proc': 'Special Proc',
    'choose_special_proc': 'Choose Special Proc',
    'valuation_category': 'Valuation Category',
    'val_category': 'Val Category',
    'price_determination': 'Price Determination',
    'price_determ': 'Price Determ',
    'quantity_install': 'Quantity Install',
    'material_asal': 'Material Asal',
    'deletion_type': 'Deletion Type',
    'choose_deletion_type': 'Choose Deletion Type',
    'material_number_tujuan': 'Material Tujuan',
    'choose_level': 'Choose Level',
    'create_new_material': 'Create New Material',
    'scope_of_supply': 'Scope of Supply',
    'up_sos': 'SOS',
    'add_scope_of_supply': 'Add Scope of Supply',
    'edit_scope_of_supply': 'Edit Scope of Supply',
    'search_sos': 'Search SOS',
    'sos_header': 'SOS Header',
    'sos_description': 'SOS Description',
    'drawing_number': 'Drawing Number',
    'choose_material_number': 'Choose Material Number',
    'choose_abc_indicator': 'Choose ABC Indicator',
    'choose_mrp_type': 'Choose MRP Type',
    'choose_mrp_controller': 'Choose MRP Controller',
    'choose_storage_location': 'Choose Storage Location',
    'choose_bin_location': 'Choose Bin Location',
    'choose_valuation_type': 'Choose Valuation Type',
    'choose_client_level_or_plant_level': 'Choose Client Level/Plant Level',
    'choose_sales_organization': 'Choose Sales Organization',
    'choose_distribution_channel': 'Choose Distribution Channel',
    'choose_warehouse_number': 'Choose Warehouse Number',
    'choose_storage_type': 'Choose Storage Type',
    'choose_critical_non_critical': 'Choose Critical/Non Critical',
    'choose_spc_proc_type': 'Choose Special Proc Type',
    'choose_price_determination': 'Choose Price Determination',
    'critical_non_critical': 'Critical/Non Critical',


    'material_request_list': 'Material Request List',
    'filter': 'Filter',
    'add_new_mcr': 'Add New MCR',
    'choose_status': 'Choose Status',
    'choose_workflow_status': 'Choose Workflow Status',
    'choose_catalog_type': 'Choose Catalog Type',
    'mcr_form_detail': 'MCR FORM DETAIL',
    'mcr_form': 'MCR FORM',
    'mcr_approval': 'MCR APPROVAL',
    'material_list': 'Material List',
    'send': 'Send',
    'add_new_item': 'Add New Item',
    'detail_material': 'Detail Material',
    'type_model_part_number': 'Type/Model/Part Number',
    'attachment_of_material': 'Attachment of Material',
    'choose_attachment': 'Choose Attachment',
    'form_service_request': 'Form Service Request',
    'parameter': 'Parameter',
    'colloquial_name': 'Colloquial Name',
    'characteristic_value': 'Characteristic Value',
    'short_description_format_&_sequence': 'Short Description Format & Sequence',
    'master_drawing': 'Master Drawing',



    'service_list': 'Service List',
    'choose_service_category': 'Choose Service Category',
    'choose_service_group': 'Choose Service Group',
    'choose_unit_of_measure': 'Choose Unit of Measure',
    'choose_valuation_class': 'Choose Valuation Class',

    'standard_item_name': 'Standard Item Name',
    'group_definition': 'Group Definition',
    'search_item_name': 'Search Item Name',
    'image': 'Image',
    'generate': 'Generate',
    'short_name': 'Short Name',
    'add_new_colloquial': 'Add New Colloquial',
    'add_new_group_class': 'Add New Group Class',
    'add_new_material_type': 'Add New Material Type',
    'add_new_material_group': 'Add New Material Group',
    'add_new_service_category': 'Add New Service Category',
    'add_new_service_group': 'Add New Service Group',
    'add_new_image_item_name': 'Add New Image Item Name',

    'characteristic': 'Characteristic',
    'char_sap': 'Characteristic SAP',
    'char_value': 'Char Value',
    'abbrev': 'Abbrev',
    'add_item_name': 'Add Item Name',
    'sample_answer': 'Sample Answer',
    'abbreviation': 'Abbreviation',
    'search_inc_item_name': 'Search INC / Item Name',
    'search_sample_answer': 'Search Sample Answer',
    'choose_approval': 'Choose Approval',
    'choose_inc': 'Choose INC',
    'choose_characteristic_master': 'Choose Characteristic Master',
    'current_value': 'Current Value',
    'update_to': 'Update To',
    'abbreviation_to': 'Abbreviation To',
    'edit_characteristic_value': 'Edit Characteristic Value',
    'sequence': 'Sequence',
    'seq_shortdes': 'Seq Shortdes',
    'seq_des': 'Seq Des',
    'seq': 'Seq',
    'separator': 'Separator',
    'choose_separator': 'Choose Separator',

    'characteristic_master': 'Characteristic Master',
    'search_characteristic': 'Search Characteristic',
    'add_characteristic': 'Add Characteristic',
    'single_multiple': 'Single/Multiple',
    'label': 'Label',
    'position_of_label': 'Position of Label',
    'space': 'Space',
    'add_characteristic_master': 'Add Characteristic Master',
    'edit_characteristic_master': 'Edit Characteristic Master',
    'search_characteristic_master': 'Search Characteristic Master',
    'position': 'Position',
    'choose_single_multiple': 'Choose Single Multiple',
    'choose_position': 'Choose Position',
    'choose_space': 'Choose Space',

    'search_unit_of_measure': 'Search Unit of Measure',
    'add_uom': 'Add UOM',
    'edit_uom': 'Edit UOM',

    'search_abbreviation': 'Search Abbreviation',
    'add_abbreviation': 'Add Abbreviation',
    'edit_abbreviation': 'Edit Abbreviation',

    'search_manufacture': 'Search Manufacture',
    'add_manufacture': 'Add Manufacture',
    'edit_manufacture': 'Edit Manufacture',
    'country': 'Country',
    'business_type': 'Business Type',
    'phone': 'Phone',
    'fax': 'Fax',
    'mobile_phone': 'Mobile Phone',
    'contact_person': 'Contact Person',
    'manufacture_name': 'Manufacture Name',

    'ngc': 'NGC',
    'search_nato': 'Search Nato',
    'please_search_to_show_data': 'Please Search To Show Data',
    'definition': 'Definition',
    'english': 'English',
    'indonesia': 'Indonesia',
    'main_group': 'Main Group',

    'add_nato': 'Add Nato',
    'edit_nato': 'Edit Nato',
    'nsc': 'NSC',
    'description': 'Description',
    'english_definition': 'English Definition',
    'indonesia_definition': 'Indonesia Definition',
    'close': 'Close',
    'add': 'Add',

    'smartcat_attachment': 'SmartCat Attachment',
    'search_attachment': 'Search Attachment',
    'name': 'Name',
    'upload_by': 'Upload By',
    'date': 'Date',
    'size': 'Size',
    'rename': 'Rename',
    'delete': 'Delete',
    'move_to': 'Move To',
    'add_new': 'Add New',
    'new_folder': 'New Folder',
    'new_file': 'New File',
    'new_link': 'New Link',
    'link_name': 'Link',
    'add_new_link': 'Add New Link',
    'add_new_folder': 'Add New Folder',
    'folder_name': 'Folder Name',
    'upload_files': 'Upload Files',
    'file_name': 'File Name',
    'file_name_option': 'File Name Option',
    'upload': 'Upload',
    'cancle': 'Cancle',
    'rename_folder_name': 'Rename Folder Name',
    'edit': 'Edit',
    'freetext': 'Freetext',
    'oem': 'OEM',
    'gen': 'GEN',
    'gen_oem': 'GEN_OEM',
    'serv': 'SERV',
    'general': 'General',
    'equipment_name': 'Equipment Name',
    'manufacture_code': 'Manufacture Code',
    'model_or_type': 'Model/Type',
    'part_number_or_drawing': 'Part Number/Drawing',
    'inc': 'INC',
    'mfr_code': 'MFR Code',
    'manref': 'Manref',
    'mfr_ref': 'MFR Ref',
    'rename_file': 'Rename File',
    'choose_file': 'Choose File',
    'drawing_master': 'Drawing Master',
    'search_drawing_master': 'Search Drawing Master',
    'drawing_name': 'Drawing Name',
    'drawing_number': 'Drawing Number',
    'date': 'Date',
    'add_new_drawing_master': 'Add New Drawing Master',
    'choose_equipment': 'Choose Equipment',
    'choose_drawing_number': 'Choose Drawing Number',
    'choose_company': 'Choose Company',

    'compose_message': 'Compose Message',
    'from': 'From',
    'to': 'To',
    'subject': 'Subject',
    'send_message': 'Send Message',
    'choose_to': 'Choose To',

    'inbox': 'Inbox',

    'sent': 'Sent',
    'search_message': 'Search Message',
    'sender': 'Sender',
    'message': 'Message',
    'view': 'View',
    'detail_message': 'Detail Message',
    'reply_message': 'Reply Message',
    'reply': 'Reply',

    'sap_connection': 'Sap Connection',
    'search_sap_connection': 'Search Sap Connection',
    'connection': 'Connection',
    'url': 'Url',
    'client': 'Client',
    'status': 'Status',
    'default': 'Default',
    'add_sap_connection': 'Add Sap Connection',
    'edit_sap_connection': 'Edit Sap Connection',
    'reset': 'Reset',
    'code': 'Code',
    'sap_sync_id': 'Sap Sync Id',
    'connection_id': 'Connection Id',
    'key': 'Key',
    'done_at': 'Done At',

    'master_data_synchron': 'Master Data Synchron',
    'search_master_data_synchron': 'Search Master Data Synchron',
    'table': 'Table',
    'data': 'Data',
    'function': 'Function',
    'input': 'Input',
    'output': 'Output',
    'synchron': 'Synchron',
    'add_master_data_synchron': 'Add Master Data Synchron',
    'edit_master_data_synchron': 'Edit Master Data Synchron',

    'sap_synchron_task': 'SAP Synchron Task',
    'search_sap_synchron_task': 'Search SAP Synchron Task',
    'mcr_number': 'MCR Number',
    'type': 'Type',
    'requested_by': 'Requested By',
    'requested_at': 'Requested At',
    'executed_at': 'Executed At',
    'view_log': 'Log',
    'synchron_task_log': 'Synchron Task Log',
    'number': 'Number',
    'edit_sap_synchron_task': 'Edit SAP Synchron Task',
    'sap_sync_task_id': 'Sap Sync Task Id',
    're_synchron': 'Re-sync',

    'account_list': 'Account List',
    'search_account': 'Search Account',
    'role': 'Role',
    'choose_role': 'Choose Role',
    'cat_type': 'Cat Type',
    'email': 'Email',
    'client': 'Client',
    'company': 'Company',
    'create_new_account': 'Create New Account',
    'user_information': 'User Information',
    'email_address': 'Email Address',
    'first_name': 'First Name',
    'last_name': 'Last Name',
    'jabatan': 'Jabatan',
    'catalog_type': 'Catalog Type',
    'other_information': 'Other Information',
    'roles': 'Roles',
    'search_roles': 'Search Roles',
    'structure_information': 'Structure Information',
    'department': 'Department',
    'section': 'Section',
    'sub_section': 'Sub Section',
    'password_confirmation': 'Password Confirmation',
    'update_account': 'Update Account',
    'change_status': 'Change Status',
    'save_changes': 'Save Changes',
    'save': 'Save',
    'update': 'Update',
    'edit_account': 'Edit Account',
    'delete_account': 'Delete Account',
    'add_sap_synchron_task': 'Add SAP Synchron Task',
    'choose_roles': 'Choose Roles',
    'choose_department': 'Choose Department',
    'choose_section': 'Choose Section',
    'choose_sub_section': 'Choose Sub Section',
    'choose_jabatan': 'Choose Jabatan',

    'search_jabatan': 'Search Jabatan',
    'add_jabatan': 'Add Jabatan',
    'edit_jabatan': 'Edit Jabatan',

    'no': 'No',
    'edit_role': 'Edit Role',
    'create_new_role': 'Create New Role',
    'update_role': 'Update Role',
    'create': 'Create',
    'role_name': 'Role Name',
    'dashboard': 'Dashboard',
    'my_work': 'My Work',
    'perfomance': 'Perfomance',
    'view_detail_cataloged': 'View Detail Cataloged',
    'view_chart_cataloged': 'View Chart Cataloged',
    'view_summary_mcr': 'View Summary MCR',
    'view_detail_mcr': 'View Detail MCR',
    'view_dashboard_my_work': 'View Dashboard My Work',
    'view_detail_kpi_cataloger': 'View Detail KPI Cataloger',
    'view_summary_catalog_status': 'View Summary Catalog Status',
    'view_online_user': 'View Online User',
    'view_summary_work_approval': 'View Summary Work Approval',
    'dictionary': 'Dictionary',
    'delete_sample': 'Delete Sample',
    'characteristic_item_name': 'Characteristic Item Name',
    'add_char_item_name': 'Add Characteristic Item Name',
    'add_char_value': 'Add Characteristic Value',
    'update_sequence_shortdes': 'Update Sequence Shortdes',
    'create_sample': 'Create Sample',
    'update_sample': 'Update Sample',
    'colloquial': 'Colloquial',
    'create_colloquial': 'Create Colloquial',
    'delete_image': 'Delete Image',
    'delete_material_group': 'Delete Material Group',
    'create_service_group': 'Create Service Group',
    'create_group_class': 'Create Group Class',
    'create_material_type': 'Create Material Type',
    'delete_colloquial': 'Delete Colloquial',
    'delete_service_group': 'Delete Service Group',
    'delete_group_class': 'Delete Group Class',
    'delete_material_type': 'Delete Material Type',
    'create_service_category': 'Create Service Category',
    'create_image': 'Create Image',
    'create_material_group': 'Create Material Group',
    'delete_service_category': 'Delete Service Category',
    'general_master_data': 'General Master Data',
    'account_assignment_group': 'Account Assignment Group',
    'bin_code': 'Bin Code',
    'harmonized_code': 'Harmonized Code',
    'hazard_code': 'Hazard Code',
    'delete_relation': 'Delete Relation',
    'general_setting': 'General Setting',
    'holding_configuration': 'Holding Configuration',
    'manage_menu': 'Manage Menu',
    'self_code': 'Self Code',
    'manage_sub_menu': 'Manage Sub Menu',
    'manage_account': 'Manage Account',
    'manage_permission': 'Manage Permission',
    'choose_sub_menu': 'Choose Sub Menu',
    'manage_role': 'Manage Role',
    'search_role': 'Search Role',
    'material': 'Material',
    'service_catalog_request': 'Service Catalog Request',
    'approval_list_view': 'Approval List View',
    'approver_1': 'Approver 1',
    'approver_2': 'Approver 2',
    'approver_3': 'Approver 3',
    'approver_4': 'Approver 4',
    'approver_5': 'Approver 5',
    'approver_6': 'Approver 6',
    'approver_7': 'Approver 7',
    'approve_mcr': 'Approve MCR',
    'material_request': 'Material Request',
    'service_request': 'Service Request',
    'material_master': 'Material Master',
    'create_material': 'Create Material',
    'multiple_view': 'Multiple View',
    'duplicate': 'Duplicate',
    'copy_value': 'Copy Value',
    'clone_all_data': 'Clone All Data',
    'clone_all_data_success': 'Successfully Clone All Data',
    'clone_all_data_error': 'Failed Clone All Data',
    'copy_all_value_success': 'Successfully Copy All Value',
    'copy_all_value_error': 'Failed Copy All Value',
    'clone': 'Clone',
    'copy': 'Copy',
    'create_weblink': 'Create Weblink',
    'change_view_description': 'Change View Description',
    'delete_manufacture': 'Delete Manufacture',
    'update_material_type': 'Update Material Type',
    'update_industry_sector': 'Update Industry Sector',
    'update_weight_unit': 'Update Weight Unit',
    'delete_keyword': 'Delete Keyword',
    'create_plant': 'Create Plant',
    'update_detail_material': 'Update Detail Material',
    'update_purchasing_group': 'Update Purchasing Group',
    'delete_accounting': 'Delete Accounting',
    'delete_pdf': 'Delete PDF',
    'characteristic_name': 'Characteristic Name',
    'update_material_group': 'Update Material Group',
    'update_stock_type': 'Update Stock Type',
    'update_catalog_type': 'Update Catalog Type',
    'create_equipment': 'Create Equipment',
    'update_plant': 'Update Plant',
    'delete_purchasing': 'Delete Purchasing',
    'update_abc_indicator': 'Update ABC Indicator',
    'delete_weblink': 'Delete Weblink',
    'create_manufacture': 'Create Manufacture',
    'update_item_type': 'Update Item Type',
    'update_uom': 'Updat UOM',
    'update_soh': 'Update SOH',
    'update_note': 'Update Note',
    'update_equipment': 'Update Equipment',
    'delete_plant': 'Delete Plant',
    'update_batch_management': 'Update Batch Management',
    'create_accounting': 'Create Accounting',
    'update_old_material_number': 'Update Old Material Number',
    'verified': 'Verified',
    'approved': 'Approved',
    'update_weblink': 'Update Weblink',
    'update_description': 'Update Description',
    'update_manufacture': 'Update Manufacture',
    'update_group_class': 'Update Group Class',
    'update_product_hierarchy': 'Update Product Hierarchy',
    'update_weight_value': 'Update Weight Value',
    'create_keyword': 'Create Keyword',
    'delete_equipment': 'Delete Equipment',
    'create_pdf': 'Create PDF',
    'create_purchasing_group': 'Create Purchasing Group',
    'update_accounting': 'Update Accounting',
    'update_ext_material_group': 'Update Ext Material Group',
    'raw_data': 'Raw Data',
    'save_workbench': 'Save Workbench',
    'catalogued': 'Catalogued',
    'not_yet_complete': 'Not Yet Complete',
    'new_mcr': 'New MCR',
    'quality_assurance': 'Quality Assurance',
    'obsolete': 'Obsolete',
    'stored_in_sap': 'Stored in SAP',
    'single_view': 'Single View',
    'compose': 'Compose',
    'view_inbox': 'View Inbox',
    'view_sent': 'View Sent',
    'report': 'Report',
    'create_report': 'Create Report',
    'generate_barcode': 'Generate Barcode',
    'update_table': 'Update Table',
    'check_duplicate': 'Check Duplicate',
    'by_description': 'By Description',
    'by_part_number': 'By Part Number',
    'sap_configuration': 'SAP Configuration',
    'master_data_configuration': 'Master Data Configuration',
    'change_default': 'Change Default',
    'service_master': 'Service Master',
    'create_keyboard': 'Create Keyboard',
    'create_multiple': 'Create Multiple',
    'update_valuation_class': 'Update Valuation Class',
    'delete_keyboard': 'Delete Keyboard',
    'update_detail_service': 'Update Detail Service',
    'update_service_category': 'Update Service Category',
    'update_item_name': 'Update Item Name',
    'update_service_group': 'Update Service Group',
    'choose_group_class': 'Choose Group Class',
    'choose_material_type': 'Choose Material Type',
    'special_procurement_type': 'Special Procurement Type',
    'search_special_procurement_type': 'Search Special Procurement Type',
    'procurement_type': 'Procurement Type',
    'search_procurement_type': 'Search Procurement Type',
    'loading_group': 'Loading Group',
    'search_loading_group': 'Search Loading Group',

    'permission': 'Permission',
    'search_permission': 'Search Permission',
    'menu': 'Menu',
    'sub_menu': 'Sub Menu',
    'guard_name': 'Guard Name',
    'create_new_permission': 'Create New Permission',
    'update_permission': 'Update Permission',

    'search_department': 'Search Department',
    'add_department': 'Add Department',
    'edit_department': 'Edit Department',

    'search_section': 'Search Section',
    'add_section': 'Add Section',
    'edit_section': 'Edit Section',

    'search_sub_section': 'Search Sub Section',
    'add_sub_section': 'Add Sub Section',
    'edit_sub_section': 'Edit Sub Section',

    'search_menu': 'Search Menu',
    'create_new_menu': 'Create New Menu',
    'update_menu': 'Update Menu',
    'choose_menu': 'Choose Menu',

    'search_sub_menu': 'Search Sub Menu',
    'create_new_sub_menu': 'Create New Sub Menu',
    'update_sub_menu': 'Update Sub Menu',

    'mail_config': 'Mail Configuration',
    'driver': 'Driver',
    'host': 'Host',
    'port': 'Port',
    'from_address': 'From Address',
    'from_name': 'From Name',
    'encryption': 'Encryption',
    'sendmail': 'Sendmail',
    'connection_test': 'Connection Test',

    'notification_master': 'Notification Master',
    'search_notification_master': 'Search Notification Master',
    'url': 'URL',
    'send_mail': 'Send Mail',
    'recipient': 'Recipient',
    'add_notification_master': 'Add Notification Master',
    'edit_notification_master': 'Edit Notification Master',

    'mcr_status': 'MCR Status',
    'search_mcr_status': 'Search MCR Status',
    'add_mcr_status': 'Add MCR Status',
    'edit_mcr_status': 'Edit MCR Status',

    'faq': 'FAQ',
    'search_faq': 'Search FAQ',
    'question': 'Question',
    'answer': 'Answer',
    'detail': 'Detail',
    'detail_or_preview': 'Detail/Preview',
    'add_faq': 'Add FAQ',
    'update_faq': 'Update FAQ',
    'detail_faq': 'Detail FAQ',

    'database_backup': 'Database Backup',
    'backup_name': 'Backup Name',
    'backup_time': 'Backup Time',
    'backup_path': 'Backup Path',
    'backup_maximum_of_files': 'Backup Maximum Of Files',

    'mail_templates': 'Mail Templates',
    'search_mail_templates': 'Search Mail Templates',
    'source_type': 'Source Type',
    'source': 'Source',
    'source_empty': 'Source is Empty',
    'template_type': 'Template Type',
    'template': 'Template',
    'add_mail_templates': 'Add Mail Templates',
    'edit_mail_templates': 'Edit Mail Templates',
    'plain_text': 'Plain Text',
    'script': 'Script',
    'choose_source': 'Choose Source',

    'catalogued_by': 'Catalogued By',
    'cat_by': 'Cat By',
    'catalog_status_updated': 'Successfully Updated Catalog Status',
    'catalog_status_error': 'Error Update Catalog Status',

    'tasks': 'Tasks',
    'search_tasks': 'Search Tasks',
    'task': 'Task',
    'add_task': 'Add Task',
    'edit_task': 'Edit Task',
    'choose_task': 'Choose Task',
    'view_target': 'View Target',
    'view_url': 'View Url',
    'process_target': 'Process Target',
    'process_url': 'Process Url',
    'duration': 'Duration',

    'task_menus': 'Task Menus',
    'search_task_menus': 'Search Task Menus',
    'update_sequence': 'Update Sequence',
    'add_task_menus': 'Add Task Menus',
    'edit_task_menus': 'Edit Task Menus',
    'symbol': 'Symbol',
    'attributes': 'Attributes',
    'icon': 'Icon',

    'workflows': 'Workflows',
    'search_workflows': 'Search Workflows',
    'add_workflows': 'Add Workflows',
    'setting_workflows': 'Setting Workflows',
    'preview_parameter': 'Preview Parameter',
    'preview': 'Preview',
    'condition': 'Condition',
    'add_condition': 'Add Condition',
    'diagram': 'Diagram',
    'clear': 'Clear',
    'operation': 'Operation',
    'add_param': 'Add Param',
    'edit_param': 'Edit Param',
    'value': 'Values',
    'query': 'Query',

    'table_column': 'Table Column',
    'search_table_column': 'Search Table Column',
    'column': 'Column',
    'add_table_column': 'Add Table Column',
    'edit_table_column': 'Edit Table Column',
    'choose_column': 'Choose Column',
    'script': 'Script',
    'search_script': 'Search Script',
    'script_code': 'Script Code',
    'script_description': 'Script Description',
    'add_script': 'Add Script',
    'script_action': 'Script Action',
    'edit_script': 'Edit Script',

    'kpi_report': 'KPI Report',
    'kpi_workbench': 'KPI Workbench',
    'export_as_excel': 'Export as Excel',
    'day': 'Day',
    'month': 'Month',
    'year': 'Year',
    'week': 'Week',

    'check_duplicate_by_description': 'Check Duplicate by Description',
    'check_duplicate_by_reference': 'Check Duplicate by Part Reference',
    'material_number': 'Material Number',
    'item_name': 'Item Name',
    'short_description': 'Short Description',
    'material_group': 'Material Group',
    'm_group': 'M Group',
    'uom': 'UOM',
    'cat_status': 'Cat Status',

    'export_work_report': 'Export Work Report',
    'export': 'Export',

    'export_data': 'Export Data',

    'import_table': 'Import Table',
    'file_xlsx': 'File (.xlsx)',
    'import_to': 'Import to',
    'import': 'Import',
    'import_errors': 'Import Errors',
    'export_error': 'Export is Failed',
    'row': 'Row',
    'attribute': 'Attribute',
    'error_message': 'Error Message',
    'values': 'Values',
    'import_history': 'Import History',
    'table_name': 'Table Name',
    'success': 'Success',
    'errors': 'Errors',
    'choose_table': 'Choose Table',
    'choose_data': 'Choose Data',

    'app_auditing': 'App Auditing',
    'auditable_type': 'Auditable Type',
    'no_model_log_in_smartcat': 'No Model Log in SmartCat',
    'this_model_log_has_been_taken_today': 'This model log been taken today',
    'no_transaction_log_in_smartcat': 'No Transaction Log in SmartCat',
    'this_transaction_log_has_been_taken_today': 'This Transaction log been taken today',
    'no_activity_log_in_smartcat': 'No Activity Log in SmartCat',
    'this_activity_log_has_been_taken_today': 'This Activity log been taken today',
    'log_code': 'Log Code',
    'log_type': 'Log Type',
    'log_key': 'Log Key',
    'ip': 'IP',
    'user_agent': 'User Agent',
    'executed_by': 'Executed By',
    'result': 'Result',
    'model_log': 'Model Log',
    'transaction_log': 'Transaction Log',
    'search_transaction_log': 'Search Transaction Log',
    'activity_log': 'Activity Log',
    'search_activity_log': 'Search Activity Log',
    'event': 'Event',
    'auditable_id': 'Auditable ID',
    'old_values': 'Old Values',
    'new_values': 'New Values',
    'id': 'ID',
    'user': 'User',
    'method': 'Method',
    'log': 'Log',

    'my_profile': 'My Profile',
    'change_photo': 'Change Photo',
    'change_password': 'Change Password',
    'profile_photo': 'Profile Photo',

    'current_password': 'Current Password',
    'new_password': 'New Password',
    'confirm_new_password': 'Confirm New Password',

    'to_do': 'To Do',
    'your_target': 'Your Target',
    'item': 'Item',
    'total_data_raw': 'Total Data RAW',
    'cataloged': 'Cataloged',
    'under_target': 'Under Target',
    'rejected': 'Rejected',
    'good_job': 'Good Job',
    'not_good': 'Not Good',
    'processing': 'Processing',
    'mcr_on_process': 'MCR On Process',
    'rejected_mcr': 'Rejected MCR',
    'approved_mcr': 'Approved MCR',
    'total': 'Total',
    'total_mcr': 'Total MCR',
    'detail_kpi_cataloger': 'Detail KPI Cataloger',
    'monitoring': 'Monitoring',
    'users': 'Users',
    'users_status': 'Users Status',
    'summary': 'Summary',
    'catalog_status': 'Catalog Status',
    'count': 'Count',
    'material_catalog_request': 'Material Catalog Request',
    'overview': 'Overview',
    'cataloged_material': 'Cataloged Material',

    'my_notifications': 'My Notifications',

    'advance_search_material': 'ADVANCE SEARCH MATERIAL',
    'manufacture_refference': 'Manufacture Reference',
    'manufacture_ref': 'Manufacture Ref',
    'choose_manref_type': 'Choose Manufacture Type',
    'equipment_code': 'Equipment Code',
    'keyword': 'Keyword',
    'up_keyword': 'KEYWORD',
    'nato_group_class': 'Nato Group Class',
    'valuation_class': 'Valuation Class',
    'val_class': 'Val Class',
    'stock_type': 'Stock Type',
    'long_description': 'Long Description',
    'up_long_description': 'LONG DESCRIPTION',
    'plant': 'Plant',
    'up_plant': 'PLANT',
    'location': 'Location',
    'shelf': 'Shelf',
    'soh': 'SOH',
    'bin': 'Bin',
    'yyyy_mm_dd': 'YYYY-MM-DD',
    'updated_by': 'Updated By',
    'status_report': 'Status Report',

    'unit_of_measure': 'Unit of Measure',
    'search': 'Search',
    'item_type': 'Item Type',
    'group_class': 'Group Class',
    'group_class_or_material_group': 'Group Class/Material Group',
    'product_hierarchy': 'Product Hierarchy',
    'industry_sector': 'Industry Sector',
    'characteristics': 'Characteristics',
    'abc_indicator': 'ABC Indicator',
    'search_abc_indicator': 'Search ABC Indicator',
    'old_material_number': 'Old Material Number',
    'up_old_material_number': 'OLD MATERIAL NUMBER',
    'ext_material_group': 'Ext Material Group',

    'mpn_number': 'Mpn Number',
    'mancode': 'Mancode',
    'spn': 'spn',
    'manref_description': 'Manref Description',
    'valid_from': 'Valid From',
    'valid_until': 'Valid Until',
    'until_date': 'Until Date',
    'till': 'Till',
    'notes': 'Notes',
    'profit_center': 'Profit Center',
    'plant_sp': 'Plant SP',
    'storage_location': 'Storage Location',
    'up_storage_location': 'STORAGE LOCATION',
    'equipment': 'Equipment',
    'up_equipment': 'EQUIPMENT',
    'qty_installed': 'Qty Installed',
    'accounting': 'Accounting',
    'up_accounting': 'ACCOUNTING',
    'price_control': 'Price Control',
    'price_ctrl': 'Price Ctrl',
    'std_price': 'STD Price',
    'price_unit': 'Price Unit',
    'po_text': 'PO TEXT',
    'created_at': 'Created At',
    'web_link': 'Web Link',
    'mat_no': 'Mat No',
    'source_description': 'Source Description',
    'tolong_diperbaiki': 'Tolong Diperbaiki',
    'reject': 'Reject',
    'cat_status_change_log': 'Cat Status Change Log',
    'updated_at': 'Updated At',
    'manufacture': 'Manufacture',
    'manufacture_refference_max_40': 'Manufacture Refference (Max 40)',
    'manref_type': 'Manref Type',
    'note': 'Note',
    'plant_code': 'Plant Code',
    'choose_plant_code': 'Choose Plant Code',
    'location_code': 'Location Code',
    'add_new_manufacture': 'Add New Manufacture',
    'add_new_plant': 'Add New Plant',
    'add_new_storage_location': 'Add New Storage Location',
    'add_new_equipment': 'Add New Equipment',
    'add_new_sub_menu': 'Add New Sub Menu',
    'update_sub_menu': 'Update Sub Menu',
    'quantity_installed': 'Quantity Installed',
    'standard_price': 'Standard Price',
    'std_price': 'Std Price',
    'add_new_accounting': 'Add New Accounting',
    'add_new_keyword': 'Add New Keyword',
    'add_new_pdf': 'Add New Pdf',
    'add_new_web_link': 'Add New Web Link',
    'pdf_file': 'PDF File',
    'update_new_role': 'Update New Role',

    'service_number': 'Service Number',
    'service_category': 'Service Category',
    'service_group': 'Service Group',
    'serv_no': 'Serv No',
    'category': 'Category',
    'group': 'Group',

    'sap_sync': 'SAP Sync',

    'search_by_characteristic_value': 'Search By Characteristic Value',
    'search_by_characteristic_and_value': 'Search By Characteristic & Value',
    'search_inc': 'Search INC / Item Name',
    'inc_or_item_name': 'INC / Item Name',

    'page_info': ':size of :total',

    'basic_data_classification': 'BASIC DATA & CLASS',
    'plant_sloc': 'PLANT & SLOC',
    'plant_storage_location': 'PLANT & STORAGE LOCATION',
    'purchasing': 'Purchasing',
    'planned_delivery': 'Planned Delivery',
    'up_purchasing': 'PURCHASING',
    'up_costing': 'COSTING',
    'keyword_old_number': 'KEYWORD & OLD NO',
    'basic_data': 'BASIC DATA',
    'classification': 'CLASSIFICATION',
    'manufacturer': 'MANUFACTURER',

    'fab_non_fab': 'Fabrication/Non Fabrication',
    'bom_non_bom': 'BOM/Non BOM',
    'crit_non_crit': 'Critical/Non Critical',
    'internal_note': 'Internal Note',
    'mpn_description': 'MPN Description',
    'mpn_desc': 'MPN Desc',
    'valid_to': 'Valid To',
    'purchase_order_text': 'PURCHASE ORDER TEXT',
    'bin_location': 'Bin Location',
    'stock_on_hand': 'Stock On Hand',
    'order_unit': 'Order Unit',
    'no_image_selected': 'No Image Selected',
    'manufacturer_reference': 'Manufacturer Reference',
    'total_item_mcr': 'Total Item MCR',
    'on_process': 'On Process',
    'all_company_code': 'All Company Code',
    'kpi_mcr_approval': 'KPI MCR Approval',
    'check_duplicate_by_part_number': 'Check Duplicate by Part Number',
    'please_complete_data': 'Please complete data :title',
    'kpi_mcr_creation': 'KPI MCR Creation',
    'new': 'New',
    'spare_parts': 'Spare Parts',
    'fast_moving': 'Fast Moving',
    'others': 'Others',
    'task_tickets': 'Task Tickets',
    'waiting': 'Waiting',
    'closed': 'Closed',
    'count_days': 'Count Days',
    'assigned_date': 'Assigned Date',
    'due_date': 'Due Date',
    'action': 'Action',
    'process': 'Process',
    'resend': 'Resend',
    'revise': 'Revise',
    'status_process': 'Status Process',
    'add_new_mcr_item_again': 'Do you want to create new item again?',
    'show': 'Show',
    'refresh': 'Refresh',
    'material_consolidation': 'Material Consolidation',
    'material_from': 'Material From',
    'plant_from': 'Plant From',
    'material_to': 'Material To',
    'plant_to': 'Plant To',
    'konsolidasi_material': 'Konsolidasi Material',
    'choose_drawing_master': 'Choose Drawing Master',
    'minimum_type_char': 'Type min :total character',
    'import_mcr_item': 'Import MCR Item',
    'mcr_item_code': 'MCR Item Code',
    'material_number_from': 'Matnum From',
    'material_number_to': 'Matnum To',
    'plant_code_from': 'Plant From',
    'plant_code_to': 'Plant To',
    'del_reason': 'Del Reason',
    'preview_description': 'Preview Description',
    'price_reference': 'Price Reference',
    'price_ref': 'Price Ref',
    'deletion_level': 'Deletion Level',
    'del_flag': 'Del Flag',
    'minimum_stock_old': 'Maximum Stock Old',
    'maximum_stock_old': 'Minimum Stock Old',
    'minimum_stock_new': 'Maximum Stock New',
    'maximum_stock_new': 'Minimum Stock New',
    'revised': 'Revised',
    'total_create': 'Create',
    'total_change': 'Change',
    'total_extend': 'Extend',
    'total_deletion': 'Deletion',
    'total_master_cataloguing': 'Create & Edit Master',
    'total_konsolidation': 'Consolidation',
    'copy_link': 'Copy Link',
    'link': 'Link',
    'overhead_group': 'OverHead Group',
    'costing_lot_size': 'Costing Lot Size',
    'with_qty_structure': 'With Qty Structure',
    'costing': 'Costing',
    'send_password_reset_link': 'Send Password Reset Link',
    'back_to_login': 'Back to Login',
    'error_email_required': 'Email Required',
    'search_mcr_item': 'Search MCR Item',
    'check_all_change_status': 'CheckAll To Change Catalog Status',
    'handle_by_another': 'Sorry, This Data Handle By Another',
    'mandatory': 'Mandatory',
    'fill_mandatory_characteristic': 'Please fill all the blank mandatory characteristic',
    'workbench_config': 'Workbench Config',
    'target': 'Target',
    'mesc': 'MESC',
    'create_main_group_mesc': 'Create Main Group MESC',
    'create_group_mesc': 'Create Group MESC',
    'edit_group_mesc': 'Edit Group MESC',
    'add_new_mesc': 'Add New MESC',
    'main_group': 'Main Group',
    'sub_group': 'Sub Group',
    'sub_sub_group': 'Sub Sub Group',
    'please_add_data': 'Please add data',
    'no_characteristic': 'No characteristic',
    'table_object': 'Table Object',
    'object': 'Object',
    'sap_task_master': 'SAP Task Master',
    'sync_name': 'Sync Name',
    'function_type': 'Function Type',
    'sap_sync_menus': 'SAP Sync Menus',
    'parent_id': 'Parent ID',
    'bom_list': 'BOM List',
    'bom_characteristic': 'BOM Characteristic',
    'add_type': 'Add Type',
    'bill_of_material': 'Bill of Material',
    'priority': 'Priority',
    'old_value': 'Old Value',
    'empty': 'Data is Empty',
    'characteristic_value_is_exists': 'Characteristic Value is Exists',
    'rcs': 'RCS',
    'cataloguing': 'Cataloguing',
    'abc_criteria': 'ABC Criteria',
    'criteria': 'Criteria',
    'criticality': 'Criticality',
    'lead_time': 'Lead time',
    'usage_value': 'Usage Value',

    // RCS
    'abc_indicator': 'ABC Indicator',
    'indicator': 'Indicator',
    'order_cost': 'Order Cost',
    'holding_cost': 'Holding Cost',
    'z': 'Z',
    'length': 'Length',
    'len': 'Len',

    // Transaction History
    'transaction_history': 'Transaction History',
    'site': 'Site',
    'purchase_requisition': 'PR',
    'requisition_date': 'PR Date',
    'purchase_order': 'PO',
    'purchase_order_date': 'PO Date',
    'promise_delivery_date': 'PD Date',
    'vendor': 'Vendor',
    'material': 'Material',
    'material_text': 'Material Text',
    'short_text': 'Short Text',
    'unit_price_pr': 'Unit Price PR',
    'currency_pr': 'Currency PR',
    'unit_price_po': 'Unit Price PO',
    'currency_po': 'Currency PO',
    'po_qty': 'PO QTY',
    'unit_of_measure': 'UOM',
    'manufacturer': 'Manufacturer',
    'gr': 'GR Date',

    // ABC Analysis
    'abc_analysis': 'ABC Analysis',
    'company': 'Company',
    'supplier': 'Supplier',
    'material_group': 'Material Group',
    'manufacturer': 'Manufacturer',
    'mat_desc_short': 'Material Description (Short Text)',
    'mat_desc_long': 'Material Description (Long Text)',
    'section': 'Section',
    'mrp_type': 'MRP Type',
    'material_category': 'Material Category',
    'mpi': 'MPI',
    'criticality_criteria': 'Criticality Criteria',
    'abc_criticality': 'A: Top Critical B: Mid Critical C: Low Critical',
    'asset': 'Asset',
    'market': 'Market',
    'total_process': 'Total Proses',
    'int_lead_time': 'Internal Lead Time (Days)',
    'avg_lead_time_int': 'Avg. Internal Lead Time (Days)',
    'int_lead_time_an': 'Internal Lead Time After Normalization (Days)',
    'ext_lead_time': 'Eksternal Lead Time (Days)',
    'avg_lead_time_eks': 'Avg. Eksternal Lead Time (Days)',
    'total_lead_time_day': 'Total Lead Time (Days)',
    'total_lead_time_month': 'Total Lead Time (Month)',
    'availibility_criteria': 'Availibility Criteria',
    'avg_unit_price': 'Average Unit Price',
    'price': 'Price',
    'currency': 'Currency',
    'usage_year': 'Usage/Year',
    'uom': 'UOM',
    'total_needed_material': 'Jumlah Material diperlukan dalam 5 thn',
    'avg_needed_material': 'Rata-rata Jml Material dlm 5th',
    'annual_usage_price': 'Annual Usage Price',
    'pareto': 'Pareto Harga Pemakaian Tahunan',
    'pareto_percentage': 'Percentage of Pareto',
    'soh': 'Stock On Hand',
    'usage_value': 'Usage Value',
    'z': 'Z',
    'avg_demand': 'AVG Demand',
    'avg_lt': 'AVG LT',
    'std': 'STD',
    'stl': 'STL',
    'safety_stock': 'Safety Stock',
    'rop': 'ROP',
    'annual_demand': 'Annual Demand',
    'k': 'K',
    'i': 'I',
    'unit_cost': 'Unit Cost',
    'eoq': 'EOQ',
    'ss_value': 'Value Safety Stock',
    'eoq_value': 'Value EOQ',
    'avg_inventory': 'AVG Inventory',
    'peak_inventory': 'Peak Inventory',
    'a_criteria': 'A : > 6 Month B : 3-6 Month C : < 3 Month',
    'from_mrp': 'FROM MRP',
    'unit_price': 'Unit Price',
    'analisa_material': 'Analisa Pemakaian Material',
    'a_b_c_usage_value': 'A : 80% B : 15% C : 5%',

    // Good Issued
    'good_issued': 'Good Issued',
    'sum_of_process': 'Sum of Process',
    'quantity': 'Quantity',
    'issued_date': 'Issued Date',
    'pivot': 'Pivot',
    'good_issued_pivot': 'Good Issued Pivot',
    'qty': 'Qty',

    // Filter
    'select_filter': 'Select Filter',
    'all': 'All',
    'sum_of_qty': 'Sum of Qty',

    'internal_lead_time': 'Internal Lead Time',
    'external_lead_time': 'External Lead Time',
    'sum_of_lead_time': 'Sum of Lead Time',
    'sum_of_po_qty': 'Sum of PO Qty',
    'transaction_history_pivot': 'Transaction History Pivot',

    'new_mat_no': 'New Mat No',
    'new_material_number': 'New Material Number',
    'title': 'title',
    'code': 'code',
    'unspsc': 'UNSPSC',
    'shortdes': 'Short Desc',
    'abbrev_name': 'Abbrev Name',
    'abbrev_type': 'Abbrev Type',
    'segment': 'Segment',
    'family': 'Family',
    'classification': 'Classification',
    'commodity': 'Commodity',
    'edit_unspsc': 'Edit UNSPSC',

    'short_description_2': 'Short Description 2',
    'short_length': 'Short Length',
    'short_length_2': 'Short Length 2',
    'item_number': 'Item Number',
    'search_text': 'Search Text',
    'brand': 'Brand',

    'release_data': 'Release Data',
    'release_request_list': 'Release Request List',

    'range_date': 'Range Date',
    'to_date_higher_than_from_date': 'To Date Invalid',

    'catalogued_at': 'Catalogued At',

    'all_values': 'All Values',
    'empty_abbrev': 'Empty Abbreviation',
    'count_zero': 'Count Zero',
    'replace': 'Replace',
    'confirm_replace': 'Are you sure to replace these words',
    'replace_char_value': 'Replace Char Values in :char',
    'find_char_val': 'Find what',
    'replace_char_val': 'Replace with',
    'fill_with_existing_char_value': 'Fill with existing character value',
    'fill_with_desired_char_value': 'Fill with desired character value',
}