<template>
   <modal :show.sync="formShow" size="lg">
        <template slot="header">
              <h5 class="modal-title">{{ tt('mcr_item_note') }}</h5>
          </template>
          <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.tracking">
              <el-table-column :label="tt('type')" :prop="tt('type')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.type }}
                  </template>
              </el-table-column>
              <el-table-column :label="tt('note')" :prop="tt('note')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.note }}
                  </template>
              </el-table-column>
              <el-table-column :label="tt('sender')" :prop="tt('sender')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.sender }}
                  </template>
              </el-table-column>
              <el-table-column :label="tt('created_at')" :prop="tt('created_at')" min-width="100px" sortable>
                  <template v-slot="{row}">
                      {{ row.created_at }}
                  </template>
              </el-table-column>
          </el-table>
    </modal>
</template>
<script>
import { mapState } from "vuex";
import Api from "@/helpers/api";
import draftList from "@/services/material/draftList.service";

    export default {
        name: 'mcritemnote',
        data() {
            return {          
                formTracking: {
                    show: false
                },  
                table: {
                    mcrNote: []
                },  
            }
        },
        props: ['mcrItemCode', 'formShow'],
        mounted() {
          this.get(this.$props.mcrItemCode);
        },
        methods:{
          get(mcr_item_code) {
            // alert(mcr_item_code);
              let context = this;
              Api(context, draftList.getMcrItemNote(mcr_item_code))
              .onSuccess(function(response) { 
                context.table.mcrNote = response.data.data;
              })
              .onError(function(error) {
                if (error.response.status == 404) {
                  context.table.mcrNote = [];
                }
              })
              .call();

              console.log(this.$props.mcrItemCode)
          },
        }
    };
</script>