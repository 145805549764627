import Service from '@/helpers/service'

export default {

    checkDuplicateByDescription(page) {
        return Service().get('CheckDuplicateByDescription?page=' + page);
    },
    checkDuplicateByPartNumber(page) {
        return Service().get('CheckDuplicateByPartNumber?page=' + page);
    },
    export_duplicate_by_description(params) {
        return Service().get('CheckDuplicateByDescription/export', {params});
    },
    export_duplicate_by_part_number(params) {
        return Service().get('CheckDuplicateByPartNumber/export', {params});
    },
    checkDuplicateByMcrItemCode(mcrItemCode) {
        return Service().get('CheckDuplicateByMcrItemCode/' + mcrItemCode);
    },
    checkDuplicateWorkbench(materialNumber){
        return Service().get('workbench-check-duplicate/' + materialNumber);
    }
}