var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.formShow,"size":"lg"},on:{"update:show":function($event){_vm.formShow=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.tt('approval_tracking')))])]),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.tracking}},[_c('el-table-column',{attrs:{"label":_vm.tt('date'),"prop":_vm.tt('date'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('status'),"prop":_vm.tt('status'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.message)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('approver'),"prop":_vm.tt('approver'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('message'),"prop":_vm.tt('message'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.note)+" ")]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }