<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{'bg-success navbar-dark': type === 'default'}"
  >
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
      <base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" :title="getLanguage()" menu-classes="dropdown-menu-sm dropdown-menu-right py-0 overflow-hidden">
        <template>                         
          <div class="list-group list-group-flush">
            <a class="list-group-item list-group-item-action" v-on:click="setLanguage('en')">
              <div class="align-items-center">
                en
              </div>
            </a>
            <a class="list-group-item list-group-item-action" v-on:click="setLanguage('id')">
              <div class="align-items-center">
                id
              </div>                    
            </a>
          </div>
        </template>
      </base-dropdown>

      <base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" icon="fa fa-search" menu-classes="dropdown-menu-md dropdown-menu-right py-0 overflow-hidden">
        <template>                         
          <div class="list-group list-group-flush">
            <!-- <router-link to="/general_layout/search-material/end-user" class="list-group-item list-group-item-action">
              <div class="align-items-center" style="font-size: 12px">
                <b>Advance Search Material</b>
              </div>
            </router-link> -->
            <router-link to="/general_layout/search-material/workbench" class="list-group-item list-group-item-action">
              <div class="align-items-center" style="font-size: 12px">
                <b>Advance Search Material</b>
              </div>
            </router-link>
            <!-- <a href="#/general_layout/search-service" class="list-group-item list-group-item-action">
              <div class="align-items-center">
                Advance Search Service
              </div>                    
            </a> -->
            <!-- <router-link to="/general_layout/search-characteristic/end-user" class="list-group-item list-group-item-action">
              <div class="align-items-center" style="font-size: 12px">
                <b>Search By Characteristic</b>
              </div>
            </router-link> -->
            <router-link to="/general_layout/search-characteristic/workbench" class="list-group-item list-group-item-action">
              <div class="align-items-center" style="font-size: 12px">
                <b>Search By Characteristic</b>
              </div>
            </router-link>
          </div>
        </template>
      </base-dropdown>
      <!-- <base-dropdown menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link"
                     icon="ni ni-ungroup">
        <template>
          <div class="row shortcuts px-4">>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                      <i class="ni ni-credit-card"></i>
                    </span>
              <small>{{ tt('cataloguing')}}</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-green">
                      <i class="ni ni-books"></i>
                    </span>
              <small>{{ tt('rcs')}}</small>
            </a>
          </div>
        </template>
      </base-dropdown> -->
      <div>
        <span class="badge badge-light text-dark" style="position: absolute">{{ table.unread.unredMessage }}</span>
        <base-dropdown class="nav-item"
                       tag="li"
                       title-classes="nav-link"
                       title-tag="a"
                       icon="fa fa-bell"
                       menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden">
          <template>
            <!-- Dropdown header -->
            <div class="px-3 py-3">
              <div class="card-header p-0">
                <span class="text-center dropdown-item"><b>Notifications</b></span>
              </div>
            </div>
            <!-- List group -->
            <div class="scroll-notifications">
              <span class="real-time-notification" v-for="v in table.data">
                <router-link @click.native="mark_as_read(v.id)" class="dropdown-item mark-as-read" :to="v.notification_data.url" :style="v.read_at == null ? null : 'background-color: #ededed'">
                    <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
                          <img :src="loadAvatar(v.user_data.image)" v-if="v.user_data.image">
                          <img src="" v-else>
                        </span>
                        <div class="media-body ml-2 d-lg-block">
                          <span class="mb-0 text-sm font-weight-bold">{{ v.user_data.name }}&nbsp;&nbsp;</span>
                          <span class="mb-0 text-sm">{{ v.notification_data.subject }}</span><br>
                          <span class="mb-0 text-muted" style="font-size: 12px"><b>{{ v.created_at}}</b></span>
                        </div>
                    </div>
                </router-link>
                <div class="dropdown-divider m-0 p-0"></div>
            </span>
          </div>
            <!-- View all -->
            <router-link to="/dashboard/my-notification" class="dropdown-item text-center py-3">See All Notifications</router-link>
          </template>
        </base-dropdown>
      </div>

      <!-- NOTES -->

      <li class="nav-item dropdown">
        <a class="nav-link nav-link-icon" href="#" @click="note = true" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Note">
        <i class="far fa-sticky-note" aria-hidden="true"></i>
        </a>
      </li>

      <el-drawer
          :visible.sync="note"
          :with-header="true"
          :title="tt('notes')"
          :close-on-press-escape="true"
          :destroy-on-close="true"
          size="24%">
            <div class="row mr-2 ml-2">
              <div class="col-12">
                <a v-if="btnNote" class="btn btn-block btn-sm btn-primary pt--10 pb-3" @click="takeANote">
                  <i class="fa fa-plus mt-3" aria-hidden="true"></i>
                  <span class="ml-3">Take a Note...</span>
                </a>
                <div class="card" v-if="formNote == true">
                  <div style="padding: 10px">
                    <input type="text" class="form-control form-control-sm border-0" placeholder="Title" v-model="notes.title">
                    <textarea v-model="notes.note" class="form-control form-control-sm border-0" cols="30" rows="5" placeholder="Take a note..." autofocus="">
                    </textarea>
                    <div class="row">
                      <div class="col-12 text-right mt-2">
                        <base-button class="btn btn-sm btn-danger" type="btn-danger" @click="closeTakeANote">Cancel</base-button>
                        <base-button v-if="notes.id == ''" class="btn btn-sm btn-default" @click="submitNote" :disabled="(notes.title == '' || notes.note == '')">
                          {{ tt('add') }}
                        </base-button>
                        <base-button v-else class="btn btn-sm btn-default" @click="updateNotes" :disabled="(notes.title == '' || notes.note == '')">
                          {{ tt('save') }}
                        </base-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showNotes">
                  <div v-if="dataNote.length > 0">
                    <div class="card my-2" v-for="dn in dataNote" :key="dn.id + 'Notes'" style="border: 1px #d4d4d4 solid" id="notes">
                      <div class="card-header border-0" style="padding: 10px; font-size: 12px">
                        <b @click="editANote(dn.id, dn.title, dn.note)">{{ dn.title}}</b>
                        <i class="fa fa-times-circle float-right" id="deleteIcon" @click="deleteNotes(dn.id)"></i>
                      </div>
                      <div class="card-body" style="margin-top: -10px; padding: 10px; font-size: 12px">
                        <span @click="editANote(dn.id, dn.title, dn.note)"><pre>{{ dn.note}}</pre></span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <img src="/assets/smartcat/empty_notes.png" class="img-fluid" style="margin-top: 150px">
                    <p class="text-center">No notes yet</p>
                  </div>
                </div>
              </div>
            </div>
      </el-drawer>
    </ul>
    <!-- <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <li class="nav-item d-sm-none">
        <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
      <base-dropdown menu-classes="dropdown-menu-lg dropdown-menu-right"
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link"
                     icon="ni ni-ungroup">
        <template>
          <div class="row shortcuts px-4">
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                      <i class="ni ni-chart-pie-35"></i>
                    </span>
              <small>SmartCat</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-orange">
                      <i class="ni ni-world"></i>
                    </span>
              <small>BOM</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                      <i class="ni ni-credit-card"></i>
                    </span>
              <small>Payments</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-green">
                      <i class="ni ni-books"></i>
                    </span>
              <small>Reports</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-purple">
                      <i class="ni ni-pin-3"></i>
                    </span>
              <small>Maps</small>
            </a>
            <a href="#!" class="col-4 shortcut-item">
                    <span class="shortcut-media avatar rounded-circle bg-gradient-yellow">
                      <i class="ni ni-basket"></i>
                    </span>
              <small>Shop</small>
            </a>
          </div>
        </template>
      </base-dropdown>
    </ul> -->
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img :src="loadAvatar(authUser.image)" v-if="authUser.image">
                    <img src="" v-else>
                  </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ authUser.name }}</span>
            </div>
          </div>
        </a>

        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <router-link to="/user/profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="#!" class="dropdown-item" v-on:click="logout()">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>

import {mapState, mapActions} from 'vuex'
import router from '@/routes/router'
import { BaseNav } from '@/components';
import config from '@/configs/config';
import Api from '@/helpers/api';
import myNotifications from '@/services/setting/myNotifications.service';
import myNotes from '@/services/setting/notes.service';

export default {
  components: {
    BaseNav
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },  
  computed: {
    ...mapState({
      authUser :state => state.auth.user
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  watch:{
    'note': function(){
      this.notes.id = '';
      this.notes.title = '';
      this.notes.note = '';
      this.showNotes = true;
      this.btnNote   = true;
      this.formNote  = false;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      note: false,
      btnNote: true,
      formNote: false,
      exampleNote: false,
      showNotes: true,
      table: {
              data : [],
              unread: [],
            },
      myNotifications: {},
      drawerNotes:false,
      notes:{
        title:'',
        note:'',
        id:''
      },
      dataNote:[]
    };
  },
  mounted() {
      this.get();
      this.getNotes();
  },
  methods: {
    ...mapActions({      
      authLogout: 'auth/logout'  
    }),
    get() { 
        let context = this;               
        Api(context, myNotifications.real_time_notification()).onSuccess(function(response) {  
            context.table.data = response.data.data.data;  
            context.table.unread = response.data.data;  
            context.onLoad = false; 
        }).onError(function(error) {                    
            if (error.response.status == 404) {
                context.table.data = [];
                context.table.total = 0;
                context.onLoad = false;
            }
        })
        .call()
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    takeANote() {
      this.btnNote = false;
      this.formNote = true;
    },
    submitNote() {
      this.exampleNote = true;
    },
    logout() {
      this.authLogout();
      router.push('/login');
    },
    loadAvatar(image) {
      return config.storageUrl+'images/image_user/'+image;
    },
    mark_as_read(id){
      let api = null;
      let context = this;

      api = Api(context, myNotifications.mark_as_read(id));
      api.onSuccess(function(response) {
          context.get();
          context.form.show = false;
      }).onError(function(error) {                    
          context.errorMessage = error.response.data;
      }).onFinish(function() {
          context.btnSave.onLoading = false;   
      })
      .call();
    },
    takeANote() {
      this.btnNote = false;
      this.formNote = true;
      this.showNotes = false;
    },
    editANote(id, title, note) {
      this.btnNote = false;
      this.formNote = true;
      this.showNotes = false;
      this.notes.id = id;
      this.notes.title = title;
      this.notes.note = note;
    },
    submitNote() {
     let context = this;
     let formData = new FormData();

     if(this.notes.title == '' && this.notes.note ==  ''){
        this.$notify({
            message: context.tt('fill_notes'),                  
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });
     } else{
       formData.append('note', this.notes.note);
       formData.append('title', this.notes.title);
        Api(context, myNotes.create(formData)).onSuccess((response) =>{
            context.getNotes()
        }).onError((eror) =>{
            context.dataNote = []
        }).onFinish(() =>{
            context.formNote    = false;
            context.showNotes = true;
            context.btnNote     = true
            context.notes.note  = '';
            context.notes.title ='';
        }).call()
     }
    },
    getNotes(){
      let context = this;
      Api(context, myNotes.get()).onSuccess((response) => {
        context.dataNote = response.data.data
      }).onError((error) =>{
        context.dataNote = [];
      }).call()
    },
    deleteNotes(id){
      let context = this;
      Api(context, myNotes.delete(id)).onSuccess((response) =>{
        context.$notify({
            message: response.data.message,                  
            type: 'success',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });
        context.getNotes();
      }).onError((error) =>{
         context.$notify({
            message: error.response.data.message,                  
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });
      }).call()
    },
    updateNotes(){
      let context = this;
      let formData = new FormData();
      let id = this.notes.id;

     if(this.notes.title == '' && this.notes.note ==  ''){
        this.$notify({
            message: context.tt('fill_notes'),                  
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });
     } else {
       formData.append('note', this.notes.note);
       formData.append('title', this.notes.title);
        Api(context, myNotes.update(id,formData)).onSuccess((response) =>{
            context.getNotes();
        }).onError((eror) =>{
            context.dataNote = []
        }).onFinish(() =>{
            context.formNote    = false;
            context.showNotes = true;
            context.btnNote     = true
            context.notes.note  = '';
            context.notes.title ='';
            context.notes.id    = '';
        }).call()
     }
    },
    closeTakeANote() {
      this.btnNote = true;
      this.formNote = false;
      this.showNotes = true;
      this.notes.id = '';
      this.notes.title = '';
      this.notes.note = '';
    },
  }
};
</script>

<style>
#notes:hover{
 box-shadow: 0 1px 2px 0 #bdbdbd,0 1px 3px 1px #e8e8e8;
 cursor:pointer;
}

#deleteIcon {
  display: none;
}

#notes:hover #deleteIcon{
  display: block;
}

.fa.fa-times-circle:hover{
  color:red
}

.el-drawer__body {
  overflow-y: auto;
}
</style>
