var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.formShow,"size":"lg"},on:{"update:show":function($event){_vm.formShow=$event}}},[_c('template',{slot:"header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.tt('mcr_item_note')))])]),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"height":"350px","header-row-class-name":"thead-light","data":_vm.table.tracking}},[_c('el-table-column',{attrs:{"label":_vm.tt('type'),"prop":_vm.tt('type'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.type)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('note'),"prop":_vm.tt('note'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.note)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('sender'),"prop":_vm.tt('sender'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sender)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.tt('created_at'),"prop":_vm.tt('created_at'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }